import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class AmazonNewOffers {
  constructor(rootSelector) {
    // this.$table = $('#amazon-offers-table');
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.filename = this.$table.attr('data-filename');
    this.$offersCount = $('.js-offers-count');
    this.helpers = renderHelpers;
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['crawled_at'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'crawled_at',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            const link = this.helpers.asinLink(this.asinUrl, data, 'offers');
            return '<a class="link" href="' + link + '">' + data + '</a>';
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
        },
        {
          name: 'upc',
          data: 'upc',
          visible: false,
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'replenishment_code',
          data: 'replenishment_code',
          visible: false,
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          visible: false,
          render: this.helpers.renderTag,
        },
        {
          name: 'condition',
          data: 'condition',
        },
        {
          name: 'price_cents',
          data: 'price_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'prime',
          data: 'prime',
        },
        {
          name: 'seller_name',
          data: 'seller_name',
          render: this.helpers.renderSeller,
        },
        {
          name: 'seller_positive_rating',
          data: 'seller_positive_rating',
        },
      ],
    });
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsv',
        filename: this.filename,
        exportOptions: { orthogonal: 'export' },
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        url: this.ajaxUrl,
        dataSrc: '',
      },
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('offers'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.$offersCount.html(json.length || '0');
    });
  }

  init() {
    this.defineTable();
    this.initTable();
  }
}

export default AmazonNewOffers;
