export class HeaderPrimary {
  root: HTMLElement;
  resizeObserver: ResizeObserver;

  constructor(root: HTMLElement) {
    this.root = root;
  }

  init = () => {
    this.resizeObserver = new ResizeObserver(() => {
      document.documentElement.style.setProperty('--header-primary-height', this.root.offsetHeight + 'px');
    });
    this.resizeObserver.observe(this.root);
  };
}

export const init = () => {
  const root = document.querySelector<HTMLElement>('#header-primary');
  if (!root) return;

  const header = new HeaderPrimary(root);
  header.init();
};

export default {
  init,
};
