import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductChanges {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.$datePicker = $('#product-changes__date');
    this.$attributePicker = $('#product-changes__attributes');
    this.$table = $('#product-changes__table');
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.filename = this.$table.attr('data-filename');
    this.beginningOn = this.$table.attr('data-beginning-on');

    this.helpers = renderHelpers;
  }

  renderAttrName(data, type, row) {
    if (data === null || data === '' || data === '[]') {
      return '-';
    } else if (data === 'asin_name') {
      return 'Product name';
    } else if (data === 'external_id') {
      return 'External ID';
    } else if (data === 'id_type') {
      return 'ID Type';
    } else if (data === 'list_price_cents') {
      return 'List price';
    } else if (data === 'parent_asin') {
      return 'Parent ASIN';
    } else if (_.includes(['ean', 'iog', 'upc', 'isbn_13'], data)) {
      return _.upperCase(data);
    } else {
      return _.capitalize(_.lowerCase(data));
    }
  }

  initDatePicker() {
    this.datePicker = this.$datePicker
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.url(this.ajaxUrl).load();
        },
      )
      .data('daterangepicker');
  }

  initAttributePicker() {
    this.attributePicker = this.$attributePicker.on('change', (e) => {
      this.dataTable.ajax.url(this.ajaxUrl).load();
    });
  }

  defineDataTable() {
    this.definition = new DataTableDefinition({
      defaultSortByColumns: ['source_as_of_date', 'attribute_name'],
      defaultSortByDirections: ['desc', 'asc'],
      columns: [
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            const link = this.helpers.asinLink(this.asinUrl, data);
            return `<a class="link" href="${link}">${data}</a>`;
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
          render: this.helpers.renderProductName,
        },
        {
          name: 'source_as_of_date',
          data: 'source_as_of_date',
          render: this.helpers.renderDate,
        },
        {
          name: 'attribute_name',
          data: 'attribute_name',
          render: this.renderAttrName,
        },
        {
          name: 'old_value',
          data: 'old_value',
          render: (data, type, row) => {
            if (row['attribute_name'] === 'list_price_cents') {
              return this.helpers.renderDollar(data);
            } else {
              return data;
            }
          },
        },
        {
          name: 'new_value',
          data: 'new_value',
          render: (data, type, row) => {
            if (row['attribute_name'] === 'list_price_cents') {
              return this.helpers.renderDollar(data);
            } else {
              return data;
            }
          },
        },
        {
          name: 'token',
          data: 'token',
          visible: false,
          render: this.helpers.renderProductLink,
        },
      ],
    });
  }

  initDataTable() {
    const buttons = [{
      extend: 'ilColvis',
      columns: this.definition.columnIndexes([
        'asin',
        'asin_name',
        'source_as_of_date',
        'attribute_name',
        'old_value',
        'new_value',
      ]),
    }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: this.filename,
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 25,
      ajax: {
        url: this.ajaxUrl,
        data: (d) => {
          d.filter = this.attributePicker.val();
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: '',
      },
      processing: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('changes'),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
    });
  }

  init() {
    this.initDatePicker();
    this.initAttributePicker();
    this.defineDataTable();
    this.initDataTable();
  }
}

export default ProductChanges;
