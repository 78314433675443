class Export {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.orgName = this.$root.attr('data-org-name');
    this.orgToken = this.$root.attr('data-org-token');
    this.$rangeSelector = $('#export-account__range-select');
    this.$monthlySelector = $('#export-account__monthly');
    this.$weeklySellector = $('#export-account__weekly');
    this.$sectionsCheckboxes = $('#export-account__section');
    this.$submit = $('#export-account__submit');
    this.$downloadTokenInput = $('#export-account__download-token');
    this.$loading = $('#export-account__loading');

    this.downloadTimer;
    this.attempts = 30;
  }

  getCookie(name) {
    const parts = document.cookie.split(name + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  setFormToken() {
    const downloadToken = new Date().getTime().toString();
    this.$downloadTokenInput.val(downloadToken);
    return downloadToken;
  }

  //https://stackoverflow.com/questions/1106377/detect-when-browser-receives-file-download
  blockResubmit() {
    const downloadToken = this.setFormToken();
    this.$submit.attr('disabled', true);
    this.$loading.show();

    this.downloadTimer = window.setInterval(() => {
      const token = this.getCookie('download_token');

      if (token === downloadToken || this.attempts === 0) {
        this.unblockSubmit();
      }

      this.attempts--;
    }, 1000);
  }

  expireCookie(cName) {
    document.cookie = encodeURIComponent(cName) + '=deleted; expires=' + new Date(0).toUTCString();
  }

  trackMixpanel() {
    const selected = [];
    this.$sectionsCheckboxes.find('input:checked').each((index, element) => {
      selected.push($(element).attr('data-section'));
    });

    const range = this.$rangeSelector.val();

    let period;
    if (this.$rangeSelector.val() === 'Monthly') {
      period = this.$monthlySelector.find('select').val();
    } else {
      period = this.$weeklySellector.find('select').val();
    }

    mixpanel.track('Export Submit', {
      'Organization Token': this.orgToken,
      'Organization Name': this.orgName,
      'Exported Sections': selected,
      'Exported Range': range,
      'Exported Period': period,
    });
  }

  unblockSubmit() {
    this.$submit.attr('disabled', false);
    this.$loading.hide();
    window.clearInterval(this.downloadTimer);
    this.expireCookie('download_token');
    this.attempts = 30;
  }

  init() {
    this.$rangeSelector.on('change', (e) => {
      if (this.$rangeSelector.val() === 'Monthly') {
        this.$monthlySelector.show();
        this.$weeklySellector.hide();
      } else {
        this.$monthlySelector.hide();
        this.$weeklySellector.show();
      }
    });

    this.$root.on('submit', () => {
      this.blockResubmit();
      this.trackMixpanel();
    });
  }
}

export default Export;
