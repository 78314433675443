import { DateRange } from 'types/components/forecasts';
import { parse, startOfWeek, startOfYear } from 'date-fns';

class ProductForecastsSalesDataProvider {
  now = new Date();
  dateRange: DateRange = {
    start: startOfWeek(startOfYear(this.now), { weekStartsOn: 0 }),
    end: startOfWeek(startOfYear(new Date(this.now.getFullYear() + 1, 0, 1)), { weekStartsOn: 0 }),
  };

  _actualSalesData = [];
  _salesReports = [];

  subscribedComponents: (HTMLElement & { actualSalesData: any })[] = [];

  set year(newYear: number) {
    this.dateRange = {
      start: startOfWeek(startOfYear(new Date(newYear, 0, 1)), { weekStartsOn: 0 }),
      end: startOfWeek(startOfYear(new Date(newYear + 1, 0, 1)), { weekStartsOn: 0 }),
    };
    this.processSales();
    this.updateSubscribedComponents();
  }

  set salesReports(reports) {
    this._salesReports = reports;
    this.processSales();
    this.updateSubscribedComponents();
  }

  subscribeComponent(component) {
    this.subscribedComponents.push(component);
  }

  updateSubscribedComponents() {
    for (const component of this.subscribedComponents) {
      component.actualSalesData = this._actualSalesData;
    }
  }

  processSales = () => {
    this._actualSalesData = this._salesReports
      .map((r) => ({
        week: parse(r.beginning_on, 'yyyy-MM-dd', this.now, { weekStartsOn: 0 }),
        shippedUnits: r.shipped_units,
      }))
      .filter((report) => report.week >= this.dateRange.start && report.week <= this.dateRange.end);
  };
}

export default ProductForecastsSalesDataProvider;
