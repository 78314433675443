import renderHelpers from '../shared/render-helpers';
import ProductReviewGraph from '../shared/product_reviews_graph';

class AmazonReviews {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.productToken = this.$table.attr('data-product-token');
    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#product-reviews-datepicker')
      .daterangepicker(
        {
          showWeekNumbers: true,
          startDate: moment().subtract(90, 'days'),
          endDate: moment(),
          opens: 'left',
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.fetchData();
        },
      )
      .data('daterangepicker');
  }

  fetchData() {
    $.ajax({
      type: 'GET',
      url: this.ajaxUrl,
      data: {
        beginning_on: this.datePicker.startDate.format('YYYY-MM-DD'),
        ending_on: this.datePicker.endDate.format('YYYY-MM-DD'),
        token: this.productToken,
      },
      success: function (data) {
        // Plotting graph
        $('#product-review-graph').attr({
          'data-reviews': JSON.stringify(data['reviews']),
        });
        const initIfExist = function (klass, selector) {
          if ($(selector).length > 0) {
            new klass(selector).init();
          }
        };

        initIfExist(ProductReviewGraph, '#product-review-graph');

        // Table generation
        const helpers = renderHelpers;
        const $table = $('#product-reviews-table');
        $table.DataTable().clear().destroy();
        const reviews = data['table_data'];

        const buttons = [];
        if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
          buttons.push({
            extend: 'ilExports',
            filename: 'Reviews',
          });
        }

        $table.DataTable({
          data: reviews,
          lengthMenu: [5, 10, 25, 50],
          paging: true,
          pageLength: 5,
          dom: TORO.shared.dataTableDefaults.dom,
          language: TORO.shared.dataTableDefaults.language('reviews'),
          buttons: buttons,
          order: [[0, 'desc']],
          columns: [
            {
              data: 'review_created_on',
            },
            {
              data: 'rating',
            },
            {
              data: 'author_name',
              render: helpers.renderReviewAuthor,
            },
            {
              data: 'author_link',
              visible: false,
            },
            {
              data: 'title',
              render: (data, type, row) => {
                if (type === 'export') {
                  return data;
                } else {
                  return helpers.renderReviewTitle(data, type, row);
                }
              },
            },
            {
              data: 'content',
              render: (data, type, row) => {
                if (type === 'export') {
                  return data;
                } else {
                  return `
                    <div data-show-more data-sm-height="150">
                      ${data}
                    </div>
                  `;
                }
              },
            },
            {
              data: 'review_link',
              visible: false,
            },
            {
              name: 'verified_purchase',
              data: 'verified_purchase',
              visible: false,
            },
            {
              name: 'crawled_at',
              data: 'crawled_at',
              render: helpers.renderDate,
            },
          ],
        });
      },
    });
  }

  init() {
    this.initDatePicker();
    this.fetchData();
  }
}

export default AmazonReviews;
