// This shows the pending... messaging for a brief moment before the post request
// completes and the user is redirected (and shown the message again on a new page)
// Not 100% necessary but i guess seems alright.
class AttachProfile {
  constructor(rootSelector) {
    this.$attachProfileBlock = $(rootSelector);
  }
  init() {
    const self = this;
    if (this.$attachProfileBlock.length > 0) {
      this.$attachProfileBlock.on('click', 'a.attach-profile', function () {
        self.$attachProfileBlock.find('div.col-xs-10:nth-child(1)').hide();
        self.$attachProfileBlock.find('div.col-xs-10:nth-child(2)').hide();
        self.$attachProfileBlock.find('div.col-xs-10:nth-child(3)').show();
      });
    }
  }
}
export default AttachProfile;
