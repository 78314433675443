export class UiTabEvent extends CustomEvent<{ name: string, group?: string }> { }

export class UiTabs extends HTMLElement {
  constructor() {
    super();
    this.role = 'tablist';
  }

  activateTab = (name: string) => {
    const group = this.getAttribute('group');

    /**
     * When this tab set is part of a group, we look for the matching contents across the whole document.
     * Otherwise, we take the adjacency between the <ui-tabs> and <ui-tab-content> as the association logic.
     */
    if (group) {
      // We need to refresh the state of all tabs & tab contents within this group.
      // This is so we can associate multiple tab sets under a single group, and each active state can essentially
      // be "linked" with each other.
      document.querySelectorAll<UiTabs>(`ui-tabs[group="${group}"]`).forEach(tabs => {
        tabs.querySelectorAll<UiTab>('ui-tab').forEach(tab => {
          tab.active = tab.getAttribute('name') === name;
        });
      });

      document.querySelectorAll<UiTabContent>(`ui-tab-content[group="${group}"]`).forEach(content => {
        content.active = content.getAttribute('name') === name;
      });

    } else {
      // default non-group behavior: act locally and use sibling contents as targets
      const tab = this.querySelector<UiTab>(`ui-tab[name="${name}"]`);
      const content = this.parentElement.querySelector<UiTabContent>(`ui-tab-content[name="${name}"]:not([group])`);
      if (!tab) return;

      const activeTab = this.querySelector<UiTab>('ui-tab[active]');
      if (activeTab) activeTab.active = false;

      const activeTabContent = this.parentElement.querySelector<UiTab>('ui-tab-content[active]:not([group])');
      if (activeTabContent) activeTabContent.active = false;

      tab.active = true;
      if (content) content.active = true;

      this.dispatchEvent(new UiTabEvent('ui:tab:activate', { bubbles: true, detail: { name } }));
    }
    const root = group ? document : this.parentElement;

    const tab = this.querySelector<UiTab>(`ui-tab[name="${name}"]`);
    const contentSelector = group ? `ui-tab-content[name="${name}"][group="${group}"]` : `ui-tab-content[name="${name}"]:not([group])`;
    const content = root.querySelector<UiTabContent>(contentSelector);
    if (!tab) return;

    const activeTab = this.querySelector<UiTab>(`ui-tab[active]`);
    if (activeTab) activeTab.active = false;

    const activeTabContentSelector = group ? `ui-tab-content[active][group="${group}"]` : 'ui-tab-content[active]:not([group])';
    const activeTabContent = root.querySelector<UiTab>(activeTabContentSelector);
    if (activeTabContent) activeTabContent.active = false;

    tab.active = true;
    if (content) content.active = true;

    this.dispatchEvent(new UiTabEvent('ui:tab:activate', { bubbles: true, detail: { name, group } }));
  }
}

export class UiTab extends HTMLElement {
  get active() {
    return this.hasAttribute('active');
  }

  set active(value) {
    if (value) this.setAttribute('active', '');
    else this.removeAttribute('active');
  }

  constructor() {
    super();
    this.role = 'presentation';
    this.addEventListener('click', this.activate);
  }

  activate = () => {
    // find the closest tab context and call the open method
    const tabs = this.closest<UiTabs>('ui-tabs');
    if (!tabs) return;

    tabs.activateTab(this.getAttribute('name'));
  }
}

export class UiTabContent extends HTMLElement {
  constructor() {
    super();
    this.role = 'tabpanel';
  }

  get active() {
    return this.hasAttribute('active');
  }

  set active(value) {
    if (value) this.setAttribute('active', '');
    else this.removeAttribute('active');
  }
}
