import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import { format, startOfYear, startOfWeek, endOfYear, eachWeekOfInterval, addWeeks } from 'date-fns';

import { ProductForecastStatistic } from 'types/components/forecasts';

const STATISTIC_TO_COLOR = {
  p90: '#00ada7',
  p80: '#a0e0a9',
  p70: '#fcd208',
  mean: '#89b4fa',
};

const SALES_COLOR = '#020617';

// Combines two arrays by the key specified by the prop parameter
// Source: https://www.30secondsofcode.org/js/s/combine-object-arrays/
const combine = (a: any[], b: any[], prop: string) => {
  const out = Object.values(
    [...a, ...b].reduce((acc, v) => {
      if (v[prop]) acc[v[prop]] = acc[v[prop]] ? { ...acc[v[prop]], ...v } : { ...v };
      return acc;
    }, {}),
  );
  return out;
}

class ProductForecastsLine extends HTMLElement {
  now = new Date();

  _domain: [Date, Date] = [
    startOfWeek(startOfYear(this.now), { weekStartsOn: 0 }),
    startOfWeek(endOfYear(this.now), { weekStartsOn: 0 }),
  ];

  _year = new Date().getFullYear();
  _data = [];
  _forecastPoints = [];
  _actualSalesData = [];
  _activeStatistics: ProductForecastStatistic[] = [];
  _activeWeek: Date | null = null;

  set data(newData) {
    this._data = newData;
    this.draw();
  }

  set forecastPoints(points) {
    this._forecastPoints = points;
    this.draw();
  }

  set actualSalesData(newData) {
    this._actualSalesData = newData;
    this.draw();
  }

  set year(newYear: number) {
    const date = new Date(newYear, 0, 1);
    this._domain = [
      startOfWeek(startOfYear(date), { weekStartsOn: 0 }),
      startOfWeek(endOfYear(date), { weekStartsOn: 0 }),
    ];
    this._year = date.getFullYear();
    this._activeWeek = null;
    this.draw();
  }

  addStatistic = (statistic: ProductForecastStatistic) => {
    const index = this._activeStatistics.indexOf(statistic);
    if (index < 0) {
      this._activeStatistics = [...this._activeStatistics, statistic];
    }
    this.draw();
  };

  removeStatistic = (statistic: ProductForecastStatistic) => {
    const index = this._activeStatistics.indexOf(statistic);
    if (index > -1) {
      this._activeStatistics = [...this._activeStatistics.slice(0, index), ...this._activeStatistics.slice(index + 1)];
    }
    this.draw();
  };

  constructor() {
    super();
  }

  draw = () => {
    let plot = null;
    const box = this.getBoundingClientRect();
    if (!this._data) return;

    const hasData =
      this._activeStatistics
        .map((s) => {
          return this._data.filter((data) => !!data[s].latest || !!data[s].min || !!data[s].max).length > 0;
        })
        .indexOf(true) > -1;

    const hasSalesData = this._actualSalesData && this._actualSalesData.length > 0;

    const domain = this._domain;

    if (!hasData && !hasSalesData) {
      plot = Plot.plot({
        height: 500,
        marginBottom: 60,
        marginLeft: 60,
        marginRight: 60,
        width: box.width,
        x: {
          label: null,
          domain,
          interval: 'week',
          type: 'time',
          tickRotate: -90,
          tickFormat: (t) => format(t, "'W'ww", { weekStartsOn: 0 }),
          tickSpacing: 80,
        },
        y: {
          label: null,
          domain: [0, 1000],
          nice: true,
          line: true,
          tickSpacing: 80,
        },
        style: {
          fontFamily: 'inherit',
        },
        marks: [
          Plot.text(['No data'], {
            y: 500,
            fontSize: 14,
            fontStyle: 'italic',
            fill: 'rgb(var(--theme-border))'
          }),
        ],
      });
    } else {
      const combinedData = combine(this._data, this._actualSalesData, 'week');
      const maxRadius = 20;

      const plotOptions: Plot.PlotOptions = {
        height: 500,
        marginBottom: 60,
        marginLeft: 60,
        marginRight: 60,
        width: box.width,
        x: {
          label: null,
          domain,
          interval: 'week',
          type: 'time',
          tickRotate: -90,
          tickFormat: (t) => format(t, "'W'ww", { weekStartsOn: 0 }),
          tickSpacing: 80,
        },
        y: {
          label: null,
          nice: true,
          line: true,
          tickSpacing: 80,
        },
        style: {
          fontFamily: 'inherit',
        },
      };

      const marks: Plot.Markish[] = [];

      // vertical week crosshairs
      marks.push(...[
        Plot.ruleX(
          combinedData,
          Plot.pointerX({
            x: 'week',
            stroke: 'rgb(var(--theme-border))',
            maxRadius,
          }),
        ),
        Plot.textX(
          combinedData,
          Plot.pointerX({
            x: 'week',
            frameAnchor: 'bottom',
            text: (d) => format(d.week, "'W'ww", { weekStartsOn: 0 }),
            textAnchor: 'end',
            lineAnchor: 'middle',
            stroke: 'rgb(var(--theme-bg))',
            strokeWidth: 6,
            fill: 'rgb(var(--theme-text))',
            rotate: -90,
            dy: 9,
            fontWeight: 700,
            maxRadius,
          }),
        ),
        Plot.textX(
          combinedData,
          Plot.pointerX({
            x: 'week',
            frameAnchor: 'bottom',
            text: (d) => d3.timeFormat('%b %d')(d['week']) + '\n' + d3.timeFormat('%Y')(d['week']),
            textAnchor: 'start',
            lineAnchor: 'top',
            stroke: 'rgb(var(--theme-bg))',
            strokeWidth: 6,
            fill: 'rgb(var(--theme-text))',
            dy: 9,
            dx: 8,
            maxRadius,
          }),
        ),
      ]);

      // horizontal value crosshairs
      if (!this._activeWeek) {
        marks.push(
          Plot.ruleY(
            combinedData,
            Plot.pointerX({
              px: 'week',
              y: 'shippedUnits',
              stroke: 'rgb(var(--theme-border))',
              maxRadius,
            }),
          ),
          Plot.textY(
            combinedData,
            Plot.pointerX({
              px: 'week',
              y: 'shippedUnits',
              frameAnchor: 'left',
              text: 'shippedUnits',
              textAnchor: 'end',
              lineAnchor: 'middle',
              stroke: 'rgb(var(--theme-bg))',
              strokeWidth: 6,
              fill: 'rgb(var(--theme-text))',
              dx: -9,
              maxRadius,
            })
          )
        );

        for (const statistic of this._activeStatistics) {
          const pointerX = (prop: string): Plot.RuleYOptions => Plot.pointerX({
            px: 'week',
            y: (d) => d[statistic] ? d[statistic][prop] : null,
            stroke: 'rgb(var(--theme-border))',
            maxRadius,
          });

          const textY = (prop: string): Plot.TextYOptions => Plot.pointerX({
            px: 'week',
            x: prop === 'latest' ? null : 'week',
            frameAnchor: prop === 'latest' ? 'left' : null,
            y: (d) => d[statistic] ? d[statistic][prop] : null,
            text: (d) => d[statistic] ? d[statistic][prop] : null,
            textAnchor: prop === 'latest' ? 'end' : 'start',
            lineAnchor: 'middle',
            stroke: 'rgb(var(--theme-bg))',
            strokeWidth: 2,
            fill: 'rgb(var(--theme-text))',
            dx: prop === 'latest' ? -9 : 9,
            maxRadius,
          });

          marks.push(...[
            Plot.ruleY(combinedData, pointerX('latest')),
            // Plot.ruleY(combinedData, pointerX('min')),
            // Plot.ruleY(combinedData, pointerX('max')),
            Plot.textY(combinedData, textY('latest')),
            Plot.textY(combinedData, textY('min')),
            Plot.textY(combinedData, textY('max')),
          ]);
        }
      }

      // add forecast areas
      for (const statistic of this._activeStatistics) {
        marks.push(Plot.areaY(this._data, {
          x: 'week',
          y1: (d) => d[statistic]?.min,
          y2: (d) => d[statistic]?.max,
          fill: STATISTIC_TO_COLOR[statistic],
          fillOpacity: 0.025,
        }));
      }

      // add forecast dots
      let dy = 32;
      for (const statistic of this._activeStatistics) {
        // filter dots by active week selection
        let filtered = false;
        let closestWeek: Date | null = null;
        if (this._activeWeek) {
          const time = this._activeWeek.getTime();
          const dates = [...new Set(this._forecastPoints.map(p => p.forecasted_on).filter(d => Math.abs(time - d.getTime()) < (1000 * 60 * 60 * 24 * 4)))];
          dates.sort((a, b) => Math.abs(time - a.getTime()) - Math.abs(time - b.getTime()));
          filtered = true;
          if (dates.length > 0) closestWeek = dates[0];
          else closestWeek = null;
        }
        const filteredPoints = filtered ? this._forecastPoints.filter(p => p.forecasted_on === closestWeek) : [];

        const dx = statistic === 'p90' ? 3 : statistic === 'p80' ? -3 : statistic === 'p70' ? -6 : 0;

        marks.push(Plot.dot(this._forecastPoints, {
          x: 'week',
          y: (d) => d[statistic],
          dx,
          r: 1,
          fill: STATISTIC_TO_COLOR[statistic],
          fillOpacity: filtered ? 0.25 : 0.5,
        }));

        if (filtered) {
          marks.push(Plot.dot(filteredPoints, {
            x: 'week',
            y: (d) => d[statistic],
            r: 2,
            fill: STATISTIC_TO_COLOR[statistic],
            fillOpacity: 1,
          }));

          // highlight active week
          marks.push(Plot.ruleX([this._activeWeek], {
            stroke: 'rgb(var(--theme-text)/25%)'
          }));

          // highlight filtered points
          marks.push(...[
            Plot.ruleY(filteredPoints, Plot.pointerX({
              px: 'week',
              y: (d) => d[statistic] ? d[statistic] : null,
              stroke: 'rgb(var(--theme-border))',
              maxRadius,
            })),
            Plot.textY(filteredPoints, Plot.pointerX({
              px: 'week',
              frameAnchor: 'left',
              y: (d) => d[statistic] ? d[statistic] : null,
              text: (d) => d[statistic] ? d[statistic] : null,
              textAnchor: 'end',
              lineAnchor: 'middle',
              stroke: 'rgb(var(--theme-bg))',
              strokeWidth: 2,
              fill: 'rgb(var(--theme-text))',
              dx: -9,
              maxRadius,
            })),
          ]);

          // show texts for hovered filtered points
          marks.push(
            Plot.text(
              filteredPoints,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: () => statistic,
                textAnchor: 'end',
                dx: 56,
                dy: dy,
                opacity: 0.5,
              }),
            ),
            Plot.text(
              filteredPoints,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: (d) => d[statistic] ? d3.format(',')(d[statistic]) : '-',
                dx: 64,
                dy: dy
              }),
            ),
          );

          dy += 12;
        }
      }

      // add statistic dashed lines
      for (const statistic of this._activeStatistics) {
        marks.push(Plot.lineY(this._data, {
          x: 'week',
          y: (d) => d[statistic]?.latest,
          stroke: STATISTIC_TO_COLOR[statistic],
          strokeWidth: 1.5,
          strokeDasharray: 5,
          strokeOpacity: this._activeWeek ? 0.25 : 1,
        }));
      }

      if (hasSalesData) {
        // shipped units line
        marks.push(
          Plot.lineY(this._actualSalesData, {
            x: 'week',
            y: 'shippedUnits',
            stroke: SALES_COLOR,
            strokeWidth: 1,
          })
        );

        // on-hover indicators on sales data
        if (!this._activeWeek) marks.push(
          Plot.dot(
            this._actualSalesData,
            Plot.pointerX({
              x: 'week',
              y: 'shippedUnits',
              r: 2,
              fill: SALES_COLOR,
              maxRadius,
            }),
          )
        );
      }

      // on-hover indicators on forecasts
      if (!this._activeWeek) {
        for (const statistic of this._activeStatistics) {
          const pointerX = (prop: string) => Plot.pointerX({
            x: 'week',
            y: (d) => d[statistic][prop],
            r: 2,
            fill: STATISTIC_TO_COLOR[statistic],
            maxRadius,
          });

          marks.push(...[
            Plot.dot(this._data, pointerX('latest')),
            Plot.dot(this._data, pointerX('min')),
            Plot.dot(this._data, pointerX('max')),
          ]);
        }
      }

      // texts
      marks.push(
        Plot.text(
          combinedData,
          Plot.pointerX({
            frameAnchor: 'top-left',
            px: 'week',
            text: () => this._activeWeek ? 'Forecast from:' : 'Overview',
            dx: 16,
            dy: -4,
          }),
        ),
        Plot.text(
          combinedData,
          Plot.pointerX({
            frameAnchor: 'top-left',
            px: 'week',
            text: (d) => format(this._activeWeek ?? d.week, "'Week' w (yyyy-MM-dd)"),
            fontWeight: 500,
            fontSize: 12,
            dx: 16,
            dy: 10,
          }),
        ),
      );

      dy = 32;
      if (!this._activeWeek && hasSalesData) {
        marks.push(
          Plot.text(
            combinedData,
            Plot.pointerX({
              frameAnchor: 'top-left',
              px: 'week',
              text: () => 'shipped\nunits',
              textAnchor: 'end',
              dx: 56,
              dy: dy,
              opacity: 0.5,
            }),
          ),
          Plot.text(
            combinedData,
            Plot.pointerX({
              frameAnchor: 'top-left',
              px: 'week',
              text: (d) => d.shippedUnits ?? '-',
              dx: 64,
              dy: dy
            }),
          )
        );

        dy += 24;
      }

      if (!this._activeWeek && this._activeStatistics.length > 0) {
        for (const statistic of this._activeStatistics) {
          marks.push(
            Plot.text(
              combinedData,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: () => statistic,
                textAnchor: 'end',
                dx: 56,
                dy: dy,
                opacity: 0.5,
              }),
            ),
            Plot.text(
              combinedData,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: (d) => d[statistic]?.min ? d3.format(',')(d[statistic].min) : '-',
                dx: 64,
                dy: dy
              }),
            ),
            Plot.text(
              combinedData,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: (d) => d[statistic]?.max ? d3.format(',')(d[statistic].max) : '-',
                dx: 112,
                dy: dy
              }),
            ),
            Plot.text(
              combinedData,
              Plot.pointerX({
                frameAnchor: 'top-left',
                px: 'week',
                text: (d) => d[statistic]?.latest ? d3.format(',')(d[statistic].latest) : '-',
                dx: 160,
                dy: dy
              }),
            )
          );

          dy += 12;
        }

        marks.push(
          Plot.text(
            combinedData,
            Plot.pointerX({
              frameAnchor: 'top-left',
              px: 'week',
              text: () => 'min',
              dx: 64,
              dy: dy,
              opacity: 0.5,
            }),
          ),
          Plot.text(
            combinedData,
            Plot.pointerX({
              frameAnchor: 'top-left',
              px: 'week',
              text: () => 'max',
              dx: 112,
              dy: dy,
              opacity: 0.5,
            }),
          ),
          Plot.text(
            combinedData,
            Plot.pointerX({
              frameAnchor: 'top-left',
              px: 'week',
              text: () => 'latest',
              dx: 160,
              dy: dy,
              opacity: 0.5,
            }),
          )
        );
      }

      marks.push(
        Plot.text(
          combinedData,
          Plot.pointerX({
            frameAnchor: 'top-left',
            px: 'week',
            text: () => this._activeWeek ? '' : '(click to focus on a week\'s forecast)',
            opacity: 0.5,
            fontSize: 8,
            dx: 16,
            dy: dy + 32,
          }),
        ),
      );

      plot = Plot.plot({ ...plotOptions, marks });

      plot.addEventListener('click', evt => {
        if (this._activeWeek === plot.value?.week) {
          this._activeWeek = null;
        } else {
          this._activeWeek = plot.value?.week || null;
        }
        this.draw();
      });
    }

    plot.id = 'product-forecasts-line--chart';
    const chartElement = document.getElementById('product-forecasts-line--chart');
    if (chartElement) this.replaceChild(plot, chartElement);
    else this.appendChild(plot);

    this.drawLegend();
  };

  drawLegend = () => {
    const wrapper = document.createElement('div');

    wrapper.className = 'px-8 pt-2 pb-4';
    wrapper.innerHTML = `
      <div class="flex items-center gap-1 justify-end text-xs">
        <div class="grid grid-flow-col grid-rows-2 gap-x-8 items-start">
          <div class="uppercase font-bold">Legend</div>
          <div class="flex justify-between items-center gap-4">
            <div class="flex items-center gap-1">
              <p class="flex-1">Shipped Units</p>
              <span class="font-light text-xs" data-toggle="tooltip" title="The actual sales of the product during the week.">
                (?)
              </span>
            </div>
            <hr class="w-4 border-1" style="border-color: ${SALES_COLOR};">
          </div>
          ${this._activeStatistics.includes('mean') ? `
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">Mean (Latest)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="The mean value of forecasts indicating that the purchased units will not exceed the amount.">
                (?)
              </span>
              </div>
              <hr class="w-4 border-dashed border-1" style="border-color: ${STATISTIC_TO_COLOR.mean};">
            </div>
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">Mean (Min - Max)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="The high-low band for the mean of forecasts across all forecasts containing the week.">
                  (?)
                </span>
              </div>
              <div class="w-4 h-4 rounded border border-text/10" style="background-color: ${STATISTIC_TO_COLOR.mean};"></div>
            </div>
          ` : ''}
          ${this._activeStatistics.includes('p90') ? `
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P90 (Latest)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A forecast indicating a 90% probability that the total purchased units will not exceed this amount.">
                (?)
              </span>
              </div>
              <hr class="w-4 border-dashed border-1" style="border-color: ${STATISTIC_TO_COLOR.p90};">
            </div>
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P90 (Min - Max)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A high-low band that shows the minimum up to maximum P90 forecast across all forecasts containing this week.">
                (?)
                </span>
              </div>
              <div class="w-4 h-4 rounded border border-text/10" style="background-color: ${STATISTIC_TO_COLOR.p90};"></div>
            </div>
          ` : ''}
          ${this._activeStatistics.includes('p80') ? `
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P80 (Latest)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A forecast indicating an 80% probability that the total purchased units will not exceed this amount.">
                (?)
              </span>
              </div>
              <hr class="w-4 border-dashed border-1" style="border-color: ${STATISTIC_TO_COLOR.p80};">
            </div>
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P80 (Min - Max)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A high-low band that shows the minimum up to maximum P80 forecast across all forecasts containing this week.">
                (?)
              </span>
              </div>
              <div class="w-4 h-4 rounded border border-text/10" style="background-color: ${STATISTIC_TO_COLOR.p80};"></div>
            </div>
          ` : ''}
          ${this._activeStatistics.includes('p70') ? `
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P70 (Latest)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A forecast indicating a 70% probability that the total purchased units will not exceed this amount.">
                (?)
              </span>
              </div>
              <hr class="w-4 border-dashed border-1" style="border-color: ${STATISTIC_TO_COLOR.p70};">
            </div>
            <div class="flex justify-between items-center gap-4">
              <div class="flex items-center gap-1">
                <p class="flex-1">P70 (Min - Max)</p>
                <span class="font-light text-xs" data-toggle="tooltip" title="A high-low band that shows the minimum up to maximum P70 forecast across all forecasts containing this week.">
                (?)
              </span>
              </div>
              <div class="w-4 h-4 rounded border border-text/10" style="background-color: ${STATISTIC_TO_COLOR.p70};"></div>
            </div>
          ` : ''}
        </div>
      </div>
    `;

    wrapper.id = 'product-forecasts-line--legend';
    const legendElement = document.getElementById('product-forecasts-line--legend');
    if (legendElement) this.replaceChild(wrapper, legendElement);
    else this.prepend(wrapper);

    /**
     * Minor hack: forcibly reference the global jQuery instance so we can spawn proper tooltips.
     * This should be replaced once we have a better non-jQuery tooltip solution in-place.
     */
    (window as any).$('#product-forecasts-line--legend [data-toggle="tooltip"]').tooltip({ container: 'body' });
  }

  connectedCallback() {
    this.draw();
    window.addEventListener('resize', this.draw);
  }
}

export default ProductForecastsLine;
