import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class AmazonBuyBoxes {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.filename = this.$table.attr('data-filename');
    this.$buyBoxesCount = $('.js-buy-boxes-count');
    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#amazon-buy-boxes-date')
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.reload();
          // this.dataTable.ajax.url(this.ajaxUrl).load()
        },
      )
      .data('daterangepicker');
  }

  updateStatusFilter() {
    const targets = [];
    this.statusFilter.find('option:selected').each(function () {
      targets.push($(this).val());
    });
    const regex = targets.map(TORO.shared.escapeRegExp).join('|');
    this.dataTable.column(this.tableDefinition.columnIndex('status')).search(regex, true, false).draw();
  }

  initStatusFilter() {
    this.statusFilter = $('#amazon-buy-boxes_status-filter');
    this.statusFilter.on('change', () => this.updateStatusFilter());
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['status'],
      defaultSortByDirections: ['asc'],
      columns: [
        {
          name: 'crawled_at',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'status',
          data: 'status',
        },
        {
          name: 'recent_critical',
          data: 'recent_critical',
        },
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            const link = this.helpers.asinLink(this.asinUrl, data, 'buy-box');
            return '<a class="link" href="' + link + '">' + data + '</a>';
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
        },
        {
          name: 'upc',
          data: 'upc',
          visible: false,
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'replenishment_code',
          data: 'replenishment_code',
          visible: false,
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          visible: false,
          render: this.helpers.renderTag,
        },
        {
          name: 'seller_text',
          data: 'seller_text',
          render: (data, type, row, meta) => {
            return data || '-';
          },
        },
        {
          name: 'seller_aid',
          data: 'seller_aid',
          render: (data, type, row, meta) => {
            if (data) {
              return `<a href="https://www.amazon.com/sp?seller=${data}" target="_blank">${data}</a>`;
            } else {
              return '-';
            }
          },
          visible: false,
        },
        {
          name: 'price_cents',
          data: 'price_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'availability',
          data: 'availability',
        },
        {
          name: 'amazon_price_cents',
          data: 'amazon_price_cents',
          render: (data, type, row, meta) => {
            return this.helpers.renderDollar(data);
          },
        },
        {
          name: 'amazon_fast_track_message',
          data: 'amazon_fast_track_message',
          render: (data, type, row, meta) => {
            return data || '-';
          },
        },
      ],
    });
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: this.filename,
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        url: this.ajaxUrl,
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: '',
      },
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('buy boxes'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.$buyBoxesCount.html(json.length || '0');
    });
  }

  applyQuerySearch() {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('buy_box_search');
    if (search) this.dataTable.search(search);
  }

  init() {
    this.initDatePicker();
    this.initStatusFilter();
    this.defineTable();
    this.initTable();

    this.updateStatusFilter();
    this.applyQuerySearch();
  }
}

export default AmazonBuyBoxes;
