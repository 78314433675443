import ProductForecastsDataProvider from 'api/product-forecasts-graph';
import ProductForecastsTableDataProvider from 'api/product-forecasts-table';
import { format } from 'date-fns';

import renderHelpers from '../shared/render-helpers';
import ProductForecastsSalesDataProvider from 'api/product-forecasts-sales';

class ProductForecastsGraph {
  constructor(rootSelector) {
    this.helpers = renderHelpers;
    this.$root = $(rootSelector);
    this.$graph = document.getElementById('product-forecasts-line');
    this.$statisticDropdown = document.getElementById('forecasts__statistic-selector');
    this.$yearDropdown = document.getElementById('forecasts__year-selector');
    this.chartDataProvider = new ProductForecastsDataProvider();
    this.chartDataProvider.subscribeComponent(this.$graph);
    this.tableDataProvider = new ProductForecastsTableDataProvider();
    this.salesDataProvider = new ProductForecastsSalesDataProvider();
    this.salesDataProvider.subscribeComponent(this.$graph);

    this.$table = $('#product-forecasts-table');
    this.tableDataProvider.subscribe(this.renderTable);

    this.$statisticDropdown.addEventListener('change', () => {
      document.querySelectorAll('#forecasts__statistic-selector option').forEach((item) => {
        if (item.selected) {
          this.$graph.addStatistic(item.value);
        } else {
          this.$graph.removeStatistic(item.value);
        }
      });
    });

    this.$yearDropdown.addEventListener('change', (event) => {
      const year = event.target.value;
      this.fetchForecasts(year);
      this.fetchSales(year);
      this.chartDataProvider.year = parseInt(year);
      this.$graph.year = parseInt(year);
      this.salesDataProvider.year = parseInt(year);
    });

    const meanOption = document.querySelector('#forecasts__statistic-selector option[value="mean"]');

    if (meanOption) {
      meanOption.selected = true;
      this.$statisticDropdown.dispatchEvent(new Event('change'));
    }
  }

  fetchForecasts = (year) => {
    $.ajax({
      type: 'POST',
      url: this.$graph.getAttribute('data-forecasts-ajax-url'),
      success: (reports) => {
        this.chartDataProvider.forecastReports = reports;
      },
      data: {
        year: year ?? new Date().getFullYear(),
      },
    });
  };

  fetchForecastsForTable = () => {
    $.ajax({
      type: 'POST',
      url: this.$graph.getAttribute('data-forecasts-all-ajax-url'),
      success: (reports) => {
        this.tableDataProvider.reports = reports;
      },
    });
  };

  fetchSales = (year) => {
    $.ajax({
      type: 'POST',
      url: this.$graph.getAttribute('data-sales-ajax-url'),
      success: (reports) => {
        this.salesDataProvider.salesReports = reports;
      },
      data: {
        year: year ?? new Date().getFullYear(),
      },
    });
  };

  fetchYears = () => {
    $.ajax({
      type: 'POST',
      url: this.$yearDropdown.getAttribute('data-ajax-url'),
      success: (years) => {
        try {
          const startingYear = parseInt(years.first_year);
          const endingYear = parseInt(years.final_year);

          const currentYear = new Date().getFullYear();
          const currentYearInSpan = startingYear <= currentYear && endingYear >= currentYear;
          for (let i = startingYear; i <= endingYear; i++) {
            const option = document.createElement('option');
            option.value = i.toString();
            option.text = i.toString();
            if ((currentYearInSpan && i === currentYear) || (!currentYearInSpan && i === endingYear)) {
              option.selected = true;
            }
            this.$yearDropdown.appendChild(option);
          }
          this.$yearDropdown.dispatchEvent(new Event('change'));
        } catch (e) { }
      },
    });
  };

  renderTable = (tableData) => {
    const buttons = [];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Product Forecasts',
      });
    }

    this.$table.DataTable({
      data: tableData,
      lengthMenu: [5, 10, 25, 50],
      paging: true,
      pageLength: 25,
      dom: TORO.shared.dataTableDefaults.dom,
      language: {
        search: '',
        searchPlaceholder: 'Search...',
      },
      buttons: buttons,
      order: [[0, 'desc']],
      columns: [
        {
          data: 'forecastedOn',
          render: (data, type) => {
            if (type === 'sort') {
              return data.getTime();
            } else if (type === 'export') {
              return format(data, 'yyyy-MM-dd');
            }

            if (data) {
              return format(data, "'Week' w (yyyy-MM-dd)");
            }
            return '-';
          },
        },
        {
          data: 'week',
          render: (data, type) => {
            if (type === 'sort') {
              return data.getTime();
            } else if (type === 'export') {
              return format(data, 'yyyy-MM-dd');
            }

            if (data) {
              return format(data, "'Week' w (yyyy-MM-dd)");
            }
            return '-';
          },
        },
        {
          data: 'p90',
          render: this.helpers.renderNumber,
        },
        {
          data: 'p80',
          render: this.helpers.renderNumber,
        },
        {
          data: 'p70',
          render: this.helpers.renderNumber,
        },
        {
          data: 'mean',
          render: this.helpers.renderNumber,
        },
        {
          data: 'shippedUnits',
          render: this.helpers.renderNumber,
        },
      ],
    });
  };

  init() {
    this.fetchYears();
    this.fetchForecastsForTable();
  }
}

export default ProductForecastsGraph;
