import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class AmazonContentChanges {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.csvUrl = this.$table.attr('data-csv-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.beginningOn = this.$table.attr('data-beginning-on');
    this.$changesCount = $('.js-changes-count');
    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#amazon-content-changes-range')
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          // this.dataTable.ajax.reload();
          this.dataTable.ajax.url(this.ajaxUrl).load();
        },
      )
      .data('daterangepicker');
  }

  initAttributeNameFilter() {
    var $actionFilter = $('#amazon-content-changes_name-filter');
    this.actionFilter = $actionFilter;
    var self = this;

    $actionFilter.on('change', function (e) {
      var targets = [];
      $actionFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      var regex = _.map(targets, function (t) {
        return TORO.shared.escapeRegExp(t);
      }).join('|');
      self.dataTable.column(self.tableDefinition.columnIndex('attribute_name')).search(regex, true, false).draw();
    });
  }

  initAttributeActionFilter() {
    var $actionFilter = $('#amazon-content-changes_action-filter');
    this.actionFilter = $actionFilter;
    var self = this;

    $actionFilter.on('change', function (e) {
      var targets = [];
      $actionFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      var regex = _.map(targets, function (t) {
        return TORO.shared.escapeRegExp(t);
      }).join('|');
      self.dataTable.column(self.tableDefinition.columnIndex('attribute_action')).search(regex, true, false).draw();
    });
  }

  renderValue(data, type, row, meta) {
    switch (row.attribute_name) {
      case 'image_urls':
        const images = $(data)
          .filter('a')
          .map(function (index, elem) {
            return `
            <a href="${$(elem).attr('href')}" target="_blank">
              <img src="${$(elem).attr('href')}" class="rounded border border-border" />
            </a>
          `;
          })
          .get()
          .join('');

        return `<div class="grid md:grid-cols-2 xl:grid-cols-3 gap-4">${images}</div>`;
        break;

      case 'a_plus_pd_html':
      case 'a_plus_batch_html':
      case 'a_plus_brand_story_html':
      case 'a_plus_sustainability_html':
      case 'book_description_html':
      case 'editorial_reviews_html':
        const text = data ? '✅' : '❌';
        const showComparison = meta.col === 10;
        const style = row.attribute_name === 'book_description_html' || row.attribute_name === 'editorial_reviews_html' ? 'html' : 'aplus';

        return `
          <div class="flex flex-wrap gap-2">
            ${text}
            ${showComparison ? `
              <a class="link" href="javascript:void(0)"
                      data-dialog-toggle='aplus-compare-modal'
                      data-aplus-compare
                      data-aplus-compare-style="${style}"
                      data-aplus-compare-old=\"${(row.old_value || '').replace(/"/g, '&quot;')}\"
                      data-aplus-compare-new=\"${(row.new_value || '').replace(/"/g, '&quot;')}\"
              >
                (Compare)
              </a>
            ` : ''}
          </div>
        `;
        break;

      default:
        return data;
    }
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['crawled_at'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'crawled_at',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            const link = this.helpers.asinLink(this.asinUrl, data, 'content');
            return '<a class="link" href="' + link + '">' + data + '</a>';
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
        },
        {
          name: 'upc',
          data: 'upc',
          visible: false,
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'replenishment_code',
          data: 'replenishment_code',
          visible: false,
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          visible: false,
          render: this.helpers.renderTagUnescaped,
        },
        {
          name: 'attribute_name',
          data: 'attribute_name',
          render: this.helpers.renderAttribute,
        },
        {
          name: 'attribute_action',
          data: 'attribute_action',
          render: this.helpers.renderAttributeAction,
        },
        {
          name: 'old_value',
          data: 'old_value_html',
          sortable: false,
          render: this.renderValue,
        },
        {
          name: 'new_value',
          data: 'new_value_html',
          sortable: false,
          render: this.renderValue,
        },
      ],
    });
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsvServer',
        url: this.csvUrl,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        type: 'POST',
        url: this.ajaxUrl,
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: 'data',
      },
      serverSide: true,
      processing: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('content changes'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.$changesCount.html(json.recordsTotal || '0');
    });
  }

  init() {
    this.initDatePicker();
    this.initAttributeNameFilter();
    this.initAttributeActionFilter();
    this.defineTable();
    this.initTable();
  }
}

export default AmazonContentChanges;
