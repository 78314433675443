import renderHelpers from '../shared/render-helpers';

class ProductReviewsGraph {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.id = this.$root.attr('id');
    this.reviewData = JSON.parse(this.$root.attr('data-reviews'));
    this.timeView = 'week';

    this.reviewData.sort(function (a, b) {
      if (a.created_on < b.created_on) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  hashWithDateAsKeys(period) {
    const periodRollup = {};
    if (this.reviewData.length > 0) {
      const firstPeriod = moment(this.reviewData[0].created_on).startOf(period);
      const lastPeriod = moment(this.reviewData[this.reviewData.length - 1].created_on).startOf(period);

      let currentPeriod = firstPeriod;
      while (currentPeriod <= lastPeriod) {
        periodRollup[currentPeriod.format('YYYY-MM-DD')] = {};
        currentPeriod = firstPeriod.add(1, period);
      }
    }
    return periodRollup;
  }

  draw(period) {
    const countMap = this.hashWithDateAsKeys(period);
    for (let i = 0; i < this.reviewData.length; i++) {
      const created_on = moment(this.reviewData[i].created_on).startOf(period).format('YYYY-MM-DD');

      if (typeof countMap[created_on][this.reviewData[i].rating] === 'undefined') {
        countMap[created_on][this.reviewData[i].rating] = 0;
      }

      countMap[created_on][this.reviewData[i].rating] += 1;
    }

    const xAxisCategories = [];
    for (let created_on in countMap) {
      xAxisCategories.push(created_on);
    }

    const reviewSeriesMap = {},
      ratings = [1, 2, 3, 4, 5];
    for (let i = 0; i < ratings.length; i++) {
      const rating = ratings[i];
      let starPostfix = ' Star';
      if (rating > 1) {
        starPostfix = ' Stars';
      }
      reviewSeriesMap[rating] = {
        name: rating + starPostfix,
        data: [],
        type: 'column',
        index: 6 - rating, // reverse series order when displayed
      };
    }

    for (let created_on in countMap) {
      for (let i in ratings) {
        var rating = ratings[i];
        if (countMap[created_on][rating]) {
          reviewSeriesMap[rating].data.push(countMap[created_on][rating]);
        } else {
          reviewSeriesMap[rating].data.push(0);
        }
      }
    }

    const reviewSeries = [];
    for (let rating in reviewSeriesMap) {
      reviewSeries.push(reviewSeriesMap[rating]);
    }

    Highcharts.chart('product-review-graph', {
      colors: TORO.shared.graphColors,
      chart: {
        zoomType: 'x',
      },
      title: {
        text: 'Review',
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      xAxis: [
        {
          categories: xAxisCategories,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: 'Review Count',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          tickInterval: 1,
          minRange: 1,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: reviewSeries,
    });
  }

  init() {
    this.draw(this.timeView);
  }
}

export default ProductReviewsGraph;
