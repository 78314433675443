import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class PurchaseOrders {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.asinUrl = this.$root.attr('data-asin-url');
    this.$datePicker = $('#purchase-orders__input');
    this.$table = $('#purchase-orders__table');
    this.helpers = renderHelpers;

    // Modal
    this.$modal = $('#purchase-orders__modal');
    this.$modalTitle = $('#purchase-orders__modal-title');
    this.$modalBody = $('#purchase-orders__modal-body');
  }

  initDatePicker() {
    this.datePicker = this.$datePicker
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.url(location.pathname + '.json').load();
        },
      )
      .data('daterangepicker');
  }

  initLocationFilter() {
    this.locationFilter = $('#purchase_orders_location-filter');

    this.locationFilter.on('change', () => {
      const targets = [];
      this.locationFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.definition.columnIndex('ship_to_location')).search(`^(${regex})$`, true, false).draw();
    });
  }

  initStatusFilter() {
    this.statusFilter = $('#purchase_orders_status-filter');

    this.statusFilter.on('change', () => {
      const targets = [];
      this.statusFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.definition.columnIndex('status')).search(`^(${regex})$`, true, false).draw();
    });
  }

  updateFilterOptions(data) {
    // location filter
    const locations = _.uniq(data.map(x => x.ship_to_location));
    const locationOptions = locations.map(x => {
      return `<option value="${x}" selected>${x}</option>`;
    });
    locationOptions.sort();
    this.locationFilter.html(locationOptions.join(''));

    // status filter
    const statuses = _.uniq(data.map(x => x.status));
    const statusOptions = statuses.map(x => {
      return `<option value="${x}" selected>${x}</option>`;
    });
    this.statusFilter.html(statusOptions.join(''));
  }

  defineTable() {
    this.definition = new DataTableDefinition({
      defaultSortByColumns: ['window_start'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'status',
          data: 'status',
          render: (data) => {
            const status = (data || '').toString().toLocaleLowerCase().replace(/(\s+|-)/g, '_');
            return `<span class="status-badge ${status}">${data}</span>`;
          }
        },
        {
          name: 'po',
          data: 'po',
        },
        {
          name: 'vendor_code',
          data: 'vendor_code',
        },
        {
          name: 'ship_to_location',
          data: 'ship_to_location',
        },
        {
          name: 'asin',
          data: 'asin',
          defaultContent: '',
          render: (data, type, row) => {
            if (data) {
              const link = this.helpers.asinLink(this.asinUrl, data);
              return '<a class="link" href="' + link + '">' + data + '</a>';
            } else {
              return '';
            }
          },
        },
        {
          name: 'external_id',
          data: 'external_id',
          render: (data, type, row) => {
            if (row.external_id_type) {
              return `${row.external_id_type}: ${data}`;
            } else {
              return data;
            }
          }
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'product_title',
          data: 'product_title',
        },
        {
          name: 'availability',
          data: 'availability',
        },
        {
          name: 'merchant_sku',
          data: 'merchant_sku',
        },
        {
          name: 'catalog_number',
          data: 'catalog_number',
        },
        {
          name: 'backordered',
          data: 'backordered',
        },
        {
          name: 'window_type',
          data: 'window_type',
        },
        {
          name: 'window_start',
          data: 'window_start',
        },
        {
          name: 'window_end',
          data: 'window_end',
        },
        {
          name: 'order_date',
          data: 'order_date',
        },
        {
          name: 'expected_date',
          data: 'expected_date',
        },
        {
          name: 'pending_cancellation_date',
          data: 'pending_cancellation_date',
        },
        {
          name: 'cancelled_date',
          data: 'cancelled_date',
        },
        {
          name: 'case_size',
          data: 'case_size',
        },
        {
          name: 'quantity_requested',
          data: 'quantity_requested',
        },
        {
          name: 'quantity_accepted',
          data: 'quantity_accepted',
        },
        {
          name: 'quantity_received',
          data: 'quantity_received',
        },
        {
          name: 'quantity_outstanding',
          data: 'quantity_outstanding',
        },
        {
          name: 'quantity_cancelled',
          data: 'quantity_cancelled',
        },
        {
          name: 'cancellation_reason',
          data: 'cancellation_reason',
        },
        {
          name: 'case_cost_cents',
          data: 'case_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'total_cost_cents',
          data: 'total_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'cost_price_cents',
          data: 'cost_price_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'accepted_cost_cents',
          data: 'accepted_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'cancelled_cost_cents',
          data: 'cancelled_cost_cents',
          render: this.helpers.renderDollar,
        },
      ],
    });
  }

  initTable() {
    const buttons = [{
      extend: 'ilColvis',
      columns: this.definition.columnIndexes([
        'status',
        'po',
        'vendor_code',
        'ship_to_location',
        'asin',
        'external_id',
        'model_number',
        'title',
        'availability',
        'merchant_sku',
        'catalog_number',
        'backordered',
        'window_type',
        'window_start',
        'window_end',
        'order_date',
        'expected_date',
        'pending_cancellation_date',
        'cancelled_date',
        'case_size',
        'quantity_requested',
        'accepted_quantity',
        'quantity_received',
        'quantity_outstanding',
        'quantity_cancelled',
        'cancellation_reason',
        'case_cost_cents',
        'total_cost_cents',
        'cost_price_cents',
        'accepted_cost_cents',
        'cancelled_cost_cents',
      ]),
    }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'PurchaseOrders',
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 25,
      ajax: {
        url: location.pathname + '.json',
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        // dataSrc: '',
        dataSrc: (json) => {
          this.updateFilterOptions(json);
          this.tableData = json;
          return json;
        },
      },
      processing: true,
      deferRender: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('Purchase Orders'),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
      columnDefs: [
        {
          width: 10,
          targets: 0,
        },
      ],
    });
  }

  init() {
    this.initDatePicker();
    this.initLocationFilter();
    this.initStatusFilter();
    this.defineTable();
    this.initTable();
  }
}

export default PurchaseOrders;
