import renderHelpers from '../shared/render-helpers';
import regionsHelper from '../shared/regions-helper';

class AnalysisGroups {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.$graph = this.$root.find('.grouping__graph');
    this.$groupingFilter = this.$root.find('.grouping__filter-grouping');
    this.$groupFilter = this.$root.find('.grouping__filter-group');
    this.$rangeFilter = this.$root.find('.grouping__filter-range');
    this.$metricFilter = this.$root.find('.grouping__filter-metric');
    this.$table = this.$root.find('.grouping__table');
    this.url = `${location.protocol}//${location.host}${location.pathname}`;

    this.selectedGrouping = null;
    this.selectedGroupingText = null;
    this.selectedRange = null;
    this.selectedMetric = null;
    this.selectedMetricText = null;

    this.currencyNamePlural = regionsHelper.getCurrencyNamePlural();

    this.graph;
    this.graphData;
    this.table;
    this.loading;
  }

  removeAllSeries() {
    while (this.graph.series.length > 0) {
      this.graph.series[0].remove();
    }
  }

  addSeries(targets) {
    _.each(targets, (seriesName) => {
      const seriesData = this.graphData[seriesName];
      this.graph.addSeries(
        {
          id: seriesName,
          name: seriesName,
          yAxis: this.yAxisIndex(),
          data: seriesData,
        },
        false,
      );
    });
  }

  removeSeries(targets) {
    _.each(targets, (seriesName) => {
      const index = _.findIndex(this.graph.series, function (s) {
        return s.userOptions.id === seriesName;
      });
      this.graph.series[index].remove();
    });
  }

  updateSeries(targets) {
    const currentSeries = _.map(this.graph.series, (s) => {
      return s.userOptions.id;
    });
    const toAdd = _.difference(targets, currentSeries);
    const toRemove = _.difference(currentSeries, targets);
    this.addSeries(toAdd);
    this.removeSeries(toRemove);
    this.updateYAxis();
    this.graph.redraw();
  }

  emptyGroupFilter() {
    this.$groupFilter.empty();
    // this.$groupFilter.selectpicker('refresh');
  }

  getTitle() {
    return `${this.selectedMetricText} by ${this.selectedGroupingText}`;
  }

  setGraphData(data) {
    this.graphData = data;
    // Set xAxis to beginningOn dates
    this.graph.xAxis[0].setCategories(data['beginning_on'], true);
    this.graph.setTitle({ text: this.getTitle() });
  }

  setGroupFilter(options) {
    _.each(options, (value, key) => {
      key = key ? key : 'None';
      const select_string = key === '(All Products)' ? 'selected' : '';
      this.$groupFilter.append(`<option value="${key}" ${select_string}>${key} (${value})</option>`);
    });
    // this.$groupFilter.selectpicker('refresh');
  }

  getTargets() {
    const targets = [];
    this.$groupFilter.find('option:selected').each(function () {
      targets.push($(this).val());
    });
    return targets;
  }

  isDollarMetric(metric) {
    return _.includes(
      ['shipped_cogs_cents', 'net_received_cents', 'sellable_on_hand_inventory_cents', 'shipped_revenue_cents'],
      metric,
    );
  }

  yAxisIndex() {
    if (this.isDollarMetric(this.selectedMetric)) {
      return 'dollars';
    } else {
      return 'units';
    }
  }

  updateYAxis() {
    // hide all axis
    _.each(this.graph.yAxis, (axis) => {
      axis.update({ visible: false }, false);
    });

    // show axis that correspond to series that are visible
    if (this.isDollarMetric(this.selectedMetric)) {
      this.graph.get('dollars').update({ visible: true }, false);
    } else {
      this.graph.get('units').update({ visible: true }, false);
    }
  }

  setSelectedFilters() {
    this.selectedGrouping = $(this.$groupingFilter.find('option:selected')[0]).val();
    this.selectedGroupingText = _.trim($(this.$groupingFilter.find('option:selected')[0]).text());
    this.selectedRange = $(this.$rangeFilter.find('option:selected')[0]).val();
    this.selectedMetric = $(this.$metricFilter.find('option:selected')[0]).val();
    this.selectedMetricText = _.trim($(this.$metricFilter.find('option:selected')[0]).text());
  }

  clearUI() {
    this.removeAllSeries();
    this.clearTable();
  }

  getGroups() {
    $.get(`${this.url}/groups_filter?grouping=${this.selectedGrouping}`, (data) => {
      this.emptyGroupFilter();
      this.setGroupFilter(data);
      this.refreshTable();
    });
  }

  getGraphData() {
    this.graph.showLoading();
    this.graph.hideNoData();
    this.loading = true;

    this.currentDataRequest = $.ajax({
      type: 'GET',
      url: `${this.url}/graph_data?grouping=${this.selectedGrouping}&range=${this.selectedRange}&metric=${this.selectedMetric}`,
      beforeSend: () => {
        // cancel previous request if its still pending.
        if (this.currentDataRequest != null) {
          this.currentDataRequest.abort();
        }
      },
    }).done((data) => {
      this.graph.hideLoading();
      this.graph.showNoData();
      this.loading = false;

      this.setGraphData(data);
      this.setTableData(data);
      this.$groupFilter[0].dispatchEvent(new Event('change'));
    });
  }

  setTableData(data) {
    const tableData = this.getDataForDataTables(data);
    const columns = this.getColumnsForDataTables(data);
    const self = this;

    const buttons = [];
    const allowExports = window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables');

    if (allowExports) {
      buttons.push({
        extend: 'ilExports',
        title: function () {
          return self.getTitle();
        },
      });
    }

    const dom = allowExports ? `
      <'dataTables_header flex items-center justify-end'B>
      <'dataTables_row'tr>
      <'dataTables_footer flex items-center justify-between'lp>
    ` : `
      <'dataTables_row'tr>
      <'dataTables_footer flex items-center justify-between'lp>
    `;

    this.table = this.$table.DataTable({
      dom: dom,
      buttons: buttons,
      destroy: true,
      data: tableData,
      pageLength: 55,
      paging: false,
      ordering: false,
      info: false,
      searching: false,
      columns: columns,
    });
  }

  clearTable() {
    if (this.table) {
      this.table.destroy();
      // Set table to null so we don't attempt to call destroy() twice, before
      // re-initializing. Calling destroy() twice causes things to not show.
      this.table = null;
      this.$table.empty();
    }
  }

  getDataForDataTables(data) {
    const tableData = [];
    _.each(data['beginning_on'], (date, index) => {
      const row = [];
      _.each(_.keys(data), (key) => {
        row.push(data[key][index]);
      });
      tableData.push(row);
    });
    return tableData;
  }

  getColumnsForDataTables(data) {
    return _.map(_.keys(data), (key) => {
      const title = key === 'beginning_on' ? 'Beginning On' : key;
      const column = {};
      column['title'] = title;
      column['name'] = title;
      column['visible'] = key === 'beginning_on';
      if (key === 'beginning_on') {
        column['visible'] = true;
        column['width'] = 100;
      } else {
        column['visible'] = false;
        if (this.isDollarMetric(this.selectedMetric)) {
          column['render'] = renderHelpers.renderDollar;
        } else {
          column['render'] = renderHelpers.renderNumber;
        }
      }
      return column;
    });
  }

  showColumns(targets) {
    _.each(targets, (columnName) => {
      const column = this.table.column(`${columnName}:name`);
      column.visible(true);
    });
  }

  hideAllColumns() {
    this.table.columns().visible(false);
    this.table.column(`Beginning On:name`).visible(true);
  }

  updateTable(targets) {
    this.hideAllColumns();
    this.showColumns(targets);
  }

  initFilters() {
    this.$groupingFilter.on('change', () => {
      this.refreshTable();
      this.getGroups();
    });

    this.$groupFilter.on('change', () => {
      const targets = this.getTargets();

      // Don't draw table/graph if the data is still loading
      if (this.loading === false) {
        this.updateSeries(targets);
        this.updateTable(targets);
      }
    });

    this.$rangeFilter.on('change', () => {
      this.refreshTable();
    });

    this.$metricFilter.on('change', () => {
      this.refreshTable();
    });

    // Trigger this event so that we initialize the groupingFilter and graphData
    this.$groupingFilter[0].dispatchEvent(new Event('change'));
  }

  refreshTable() {
    this.setSelectedFilters();
    this.clearUI();
    this.getGraphData();
  }

  initGraph() {
    const self = this;
    self.graph = Highcharts.chart(this.$graph[0], {
      chart: {
        zoomType: 'x',
        reflow: false,
      },
      boost: {
        seriesThreshold: 0,
      },
      title: {
        text: '',
      },
      xAxis: [
        {
          title: {
            text: 'Beginning On',
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
      ],
      yAxis: [
        {
          id: 'dollars',
          title: {
            text: self.currencyNamePlural,
          },
          labels: {
            enabled: true,
            formatter: function () {
              return renderHelpers.renderDollarShort(this.value);
            },
          },
          visible: false,
        },
        {
          id: 'units',
          title: {
            text: 'Units',
          },
          labels: {
            enabled: true,
            formatter: function () {
              return renderHelpers.renderNumberShort(this.value);
            },
          },
          visible: false,
        },
      ],
      tooltip: {
        formatter: function () {
          let tooltip = `<b>${this.series.name}</b><br/>`;
          if (self.isDollarMetric(self.selectedMetric)) {
            tooltip += `${renderHelpers.renderDollar(this.y)} (Dollars)`;
          } else {
            tooltip += `${renderHelpers.renderNumber(this.y)} (Units)`;
          }
          return tooltip;
        },
      },
    });
  }

  init() {
    this.initGraph();
    this.initFilters();
  }
}

export default AnalysisGroups;
