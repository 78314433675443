export class ShowMore {
  root: HTMLElement;
  rootObserver?: ResizeObserver;
  toggle: HTMLElement;
  enabled = false;
  open = false;

  constructor(root: HTMLElement) {
    this.root = root;
    this.root.dataset.init = '';

    this.open = root.dataset.open === '' || !!root.dataset.open;
    this.toggle = document.createElement('button');
    this.toggle.classList.add('show-more-toggle');
    this.toggle.innerText = 'Show More';

    const hr = document.createElement('hr');
    hr.classList.add('show-more-border');

    this.root.appendChild(this.toggle);
    this.root.appendChild(hr);
  }

  init = () => {
    this.toggle.addEventListener('click', this.toggleOpen);

    this.rootObserver = new ResizeObserver(this.updateState);
    this.rootObserver.observe(this.root);

    this.updateState();
  };

  updateState = () => {
    // don't update state when root container is not visible
    if (!this.root || this.root.offsetParent == null) return;

    window.requestAnimationFrame(() => {
      let maxHeight = parseInt(this.root.dataset.smHeight);
      if (isNaN(maxHeight)) maxHeight = 160;

      this.enabled = this.root.scrollHeight > maxHeight;

      if (this.enabled) {
        this.root.dataset.enabled = '';
        this.toggle.classList.remove('hidden');

        this.root.style.setProperty('--show-more-height', this.open ? 'auto' : `${maxHeight}px`);

        if (this.open) {
          this.root.dataset.open = '';
          this.toggle.innerText = 'Show Less';
        } else {
          delete this.root.dataset.open;
          this.toggle.innerText = 'Show More';
        }
      } else {
        delete this.root.dataset.enabled;
        this.toggle.classList.add('hidden');
      }
    });
  }

  toggleOpen = () => {
    this.open = !this.open;
    this.updateState();
  }
}

export const init = () => {
  const render = () => {
    document.querySelectorAll<HTMLElement>('[data-show-more]:not([data-init])').forEach((root) => {
      const showMore = new ShowMore(root);
      showMore.init();
    });
  }

  const mutationObserver = new MutationObserver(render);
  mutationObserver.observe(document.body, {
    subtree: true,
    childList: true,
    attributeFilter: ['data-show-more']
  });
  render();
};

export default {
  init,
};
