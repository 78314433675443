import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';

export default class ProductBestSellerRanksChart extends HTMLElement {
  _startDate = '';
  _endDate = '';
  _bestSellerRanks = [];

  set startDate(date) {
    this._startDate = date;
  }

  set endDate(date) {
    this._endDate = date;
  }

  set bestSellerRanks(data) {
    this._bestSellerRanks = data;
    this.draw();
  }

  constructor() {
    super();
    this.draw();
  }

  draw = () => {
    const graph = this;
    const bestSellerRanks = this._bestSellerRanks;

    if (bestSellerRanks.length === 0) {
      graph.innerHTML = `
        <div class="min-h-24 flex items-center justify-center text-text/50 italic">
          No best seller ranks crawled within this time frame.
        </div>
      `;
      return;
    }

    const groupedData = {};
    for (const bsr of bestSellerRanks) {
      const cat = bsr.amazon_category_id;
      if (!groupedData[cat]) groupedData[cat] = [];
      groupedData[cat].push({
        ...bsr,
        date: new Date(bsr.crawled_at),
      });
    }

    const colors = [
      'rgb(var(--theme-text)/75%)',
      'rgb(var(--theme-accent))',
      'rgb(var(--theme-accent-2))',
      'rgb(var(--theme-accent-3))',
    ];
    const box = graph.getBoundingClientRect();
    const plots = [];
    const keys = Object.keys(groupedData);

    // generate a plot for each category
    for (const [i, category] of keys.entries()) {
      const data = groupedData[category];

      const categoryName = data.length > 0 ? data[0].category_name : '?';
      const color = colors[i % colors.length];

      const ranks = data.map(d => d.rank);
      const yTicks = [Math.min(...ranks), Math.max(...ranks)];

      const plot = Plot.plot({
        style: {
          fontFamily: 'inherit',
          fontWeight: '600',
          fontSize: '0.7rem',
          overflow: 'visible',
        },
        width: box.width,
        height: 160,
        marginLeft: 0,
        marginRight: 40,
        x: {
          domain: [new Date(this._startDate), new Date(this._endDate)],
          axis: null,
          grid: true,
        },
        y: {
          axis: 'right',
          ticks: yTicks,
          tickFormat: (d) => `#${d3.format('.2s')(d)}`,
          label: null,
          reverse: true,
        },
        marks: [
          Plot.ruleY(yTicks, {
            stroke: 'rgb(var(--theme-border))',
          }),
          Plot.ruleX(
            data,
            Plot.pointerX({
              x: 'date',
              stroke: color,
              strokeOpacity: 0.25,
            })
          ),
          Plot.ruleY(
            data,
            Plot.pointerX({
              px: 'date',
              y: 'rank',
              stroke: color,
              strokeOpacity: 0.25,
            })
          ),
          Plot.lineX(data, {
            x: 'date',
            y: 'rank',
            stroke: color,
            strokeWidth: 0.5,
          }),
          Plot.dot(data, {
            x: 'date',
            y: 'rank',
            stroke: null,
            fill: color,
          }),
          Plot.text([categoryName], {
            textAnchor: 'start',
            frameAnchor: 'top-left',
            fill: color,
            fontSize: 14,
            dy: -20,
          }),
          Plot.text(
            data,
            Plot.pointerX({
              px: 'date',
              text: (d) => `Top #${d3.format(',')(d.rank)} - ${d3.utcFormat('%Y-%m-%d')(d.date)}`,
              textAnchor: 'end',
              frameAnchor: 'top-right',
              fill: color,
              fontSize: 14,
              dy: -20,
            })
          ),
        ]
      });

      plots.push(plot);
    }

    // generate sticky last-row axis
    const stickyAxis = Plot.plot({
      style: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '0.7rem',
        overflow: 'visible',
        position: 'sticky',
        bottom: '0',
        background: 'white',
      },
      width: box.width,
      height: 20,
      marginLeft: 0,
      marginRight: 40,
      x: {
        domain: [new Date(this._startDate), new Date(this._endDate)],
        axis: 'bottom',
        grid: true,
      },
      y: {
        axis: null,
      },
      marks: []
    });
    plots.push(stickyAxis);


    while (graph.firstChild) graph.removeChild(graph.firstChild);
    for (const plot of plots) graph.appendChild(plot);
  }

  connectedCallback() {
    this.draw();
    window.addEventListener('resize', this.draw);
    window.addEventListener('ui:tab:activate', this.draw);
  }

  disconnectedCallback() {
    //
  }

  adoptedCallback() {
    this.draw();
  }

  attributeChangedCallback() {
    this.draw();
  }
}
