class VcChangeTable {
  constructor(rootSelector) {
    this.$rootSelector = $(rootSelector);
  }

  init() {
    const buttons = [];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Vendor Central Content Changes',
      });
    }

    const dataTable = this.$rootSelector.DataTable({
      lengthMenu: [5, 10, 25, 50],
      paging: true,
      pageLength: 5,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('changes'),
      buttons: buttons,
      order: [[0, 'desc']],
    });
  }
}

export default VcChangeTable;
