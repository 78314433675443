import { ChainedCommands, Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';

/**
 * Primary editor class instantiator.
 */
export class TiptapEditor {
  container: HTMLElement;
  target: HTMLElement;
  textarea: HTMLTextAreaElement;
  editor: Editor;

  constructor(container: HTMLElement) {
    this.container = container;
    this.textarea = this.container.querySelector('textarea');
    if (!this.container || !this.textarea) return;

    this.textarea.style.display = 'none';

    this.target = document.createElement('div');
    this.target.className = 'tiptap-editor prose noclamp';
    this.container.appendChild(this.target);
  }

  init = () => {
    let content = '<p></p>';
    if (this.textarea.value) content = this.textarea.value.replace(/\n/g, ' ');

    this.editor = new Editor({
      element: this.target,
      extensions: [
        StarterKit,
        Link,
        Image,
      ],
      content,
      onUpdate: ({ editor }) => {
        this.textarea.value = editor.getHTML();
      }
    });

    this.setupToolbar();
  };

  setupToolbar = () => {
    const toolbar = new TiptapEditorToolbar(this.editor);
    toolbar.className = 'tiptap-toolbar';
    this.target.appendChild(toolbar);
  }
}

/**
 * Custom HTML element for in-focus popup toolbar.
 */
export class TiptapEditorToolbar extends HTMLElement {
  static observedAttributes = ['open'];

  constructor(private editor: Editor) {
    super();
  }

  open = () => {
    this.setAttribute('open', '');
  }

  close = () => {
    this.removeAttribute('open');
  }

  createToolbarDivider = () => {
    const divider = document.createElement('div');
    divider.className = 'toolbar-divider';
    return divider;
  }

  connectedCallback() {
    this.editor.on('focus', this.open);
    this.editor.on('blur', this.close);

    const activeNodeButton = new ToolbarActiveNodeButton(this.editor);
    this.appendChild(activeNodeButton);

    this.appendChild(new ToolbarButton(this.editor, 'p', c => c.setParagraph()));
    this.appendChild(new ToolbarButton(this.editor, 'h1', c => c.setHeading({ level: 1 })));
    this.appendChild(new ToolbarButton(this.editor, 'h2', c => c.setHeading({ level: 2 })));
    this.appendChild(new ToolbarButton(this.editor, 'h3', c => c.setHeading({ level: 3 })));

    this.appendChild(this.createToolbarDivider());

    this.appendChild(new ToolbarMarkButton(
      this.editor,
      `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"/></svg>`,
      'bold'
    ));
    this.appendChild(new ToolbarMarkButton(
      this.editor,
      `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"/></svg>`,
      'italic'
    ));
    this.appendChild(new ToolbarMarkButton(
      this.editor,
      `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M161.3 144c3.2-17.2 14-30.1 33.7-38.6c21.1-9 51.8-12.3 88.6-6.5c11.9 1.9 48.8 9.1 60.1 12c17.1 4.5 34.6-5.6 39.2-22.7s-5.6-34.6-22.7-39.2c-14.3-3.8-53.6-11.4-66.6-13.4c-44.7-7-88.3-4.2-123.7 10.9c-36.5 15.6-64.4 44.8-71.8 87.3c-.1 .6-.2 1.1-.2 1.7c-2.8 23.9 .5 45.6 10.1 64.6c4.5 9 10.2 16.9 16.7 23.9H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H270.1c-.1 0-.3-.1-.4-.1l-1.1-.3c-36-10.8-65.2-19.6-85.2-33.1c-9.3-6.3-15-12.6-18.2-19.1c-3.1-6.1-5.2-14.6-3.8-27.4zM348.9 337.2c2.7 6.5 4.4 15.8 1.9 30.1c-3 17.6-13.8 30.8-33.9 39.4c-21.1 9-51.7 12.3-88.5 6.5c-18-2.9-49.1-13.5-74.4-22.1c-5.6-1.9-11-3.7-15.9-5.4c-16.8-5.6-34.9 3.5-40.5 20.3s3.5 34.9 20.3 40.5c3.6 1.2 7.9 2.7 12.7 4.3l0 0 0 0c24.9 8.5 63.6 21.7 87.6 25.6l0 0 .2 0c44.7 7 88.3 4.2 123.7-10.9c36.5-15.6 64.4-44.8 71.8-87.3c3.6-21 2.7-40.4-3.1-58.1H335.1c7 5.6 11.4 11.2 13.9 17.2z"/></svg>`,
      'strike'
    ));
    this.appendChild(new ToolbarMarkButton(
      this.editor,
      `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>`,
      'code'
    ));

    this.appendChild(this.createToolbarDivider());

    this.appendChild(
      new ToolbarButton(
        this.editor,
        `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>`,
        (c, dryRun) => {
          if (this.editor.isActive('link')) {
            return c.unsetLink();
          } else {
            const href = dryRun ? '' : window.prompt('Please specify link URL:') || '';
            return c.setLink({ href });
          }
        },
        () => this.editor.isActive('link'),
      )
    );

    this.appendChild(
      new ToolbarButton(
        this.editor,
        `<svg class="w-4 h-4 py-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>`,
        (c, dryRun) => {
          const defaultImg = 'http://localhost:3000/default.jpg';
          const src = dryRun ? defaultImg : window.prompt('Please specify image URL:') || defaultImg;
          return c.setImage({ src });
        },
      )
    );
  }
}

export class ToolbarActiveNodeButton extends HTMLButtonElement {
  constructor(private editor: Editor) {
    super();
  }

  update = (tr: Transaction) => {
    if (tr.selection.$from.sameParent(tr.selection.$to)) {
      const nodes = [];

      // process ancestor nodes
      let i = 0, n = null;
      do {
        n = tr.selection.$from.node(i);
        if (n) {
          const text = this.getNodeText(n);
          if (text) nodes.push(text);
        }
        i++;
      } while (n);

      // process leaf node
      const leaf = tr.selection.$from.nodeAfter || tr.selection.$to.nodeBefore;
      if (leaf) {
        const text = this.getNodeText(leaf);
        if (text) nodes.push(text);
      }

      this.innerHTML = nodes.join('<span class="mx-2 opacity-25">🢒</span>');
    } else {
      this.innerText = '···';
    }
  }

  getNodeText = (node: Node) => {
    switch (node.type.name) {
      case 'paragraph':
        return 'p';
      case 'heading':
        return `h${node.attrs.level}`;
      case 'bulletList':
        return 'ul';
      case 'orderedList':
        return 'ol';
      case 'blockquote':
        return 'quote';
      case 'codeBlock':
        return 'code';
      case 'image':
        return 'img';
    }
  }

  connectedCallback() {
    this.type = 'button';
    this.disabled = true;
    this.className = 'toolbar-button pressed';

    this.editor.on('transaction', ({ transaction }) => this.update(transaction));
  }
}

export class ToolbarButton extends HTMLButtonElement {
  constructor(
    private editor: Editor,
    private content: string,
    private action: (chain: ChainedCommands, dryRun: boolean) => ChainedCommands,
    private pressed?: () => boolean,
  ) {
    super();
  }

  update = () => {
    if (this.action(this.editor.can().chain(), true).run()) {
      this.classList.remove('hidden');
    } else {
      this.classList.add('hidden');
    }

    if (this.pressed) {
      if (this.pressed()) {
        this.classList.add('pressed');
      } else {
        this.classList.remove('pressed');
      }
    }
  }

  execute = () => {
    this.action(this.editor.chain(), false).focus().run();
  }

  connectedCallback() {
    this.type = 'button';
    this.className = 'toolbar-button';
    this.innerHTML = this.content;

    this.editor.on('transaction', this.update);
    this.addEventListener('mousedown', (evt) => evt.preventDefault());
    this.addEventListener('click', this.execute);
  }
}

export class ToolbarMarkButton extends HTMLButtonElement {
  constructor(private editor: Editor, private content: string, private markType: string) {
    super();
  }

  update = () => {
    if (this.editor.isActive(this.markType)) this.classList.add('pressed');
    else this.classList.remove('pressed');
  }

  connectedCallback() {
    this.type = 'button';
    this.className = 'toolbar-button';
    this.innerHTML = this.content;

    this.editor.on('transaction', this.update);
    this.addEventListener('mousedown', (evt) => evt.preventDefault());
    this.addEventListener('click', () => {
      this.editor.chain().toggleMark(this.markType).focus().run();
    });
  }
}

export const init = () => {
  // register custom toolbar element
  customElements.define('tiptap-editor-toolbar', TiptapEditorToolbar);
  customElements.define('tiptap-editor-toolbar-active-node-button', ToolbarActiveNodeButton, { extends: 'button' });
  customElements.define('tiptap-editor-toolbar-button', ToolbarButton, { extends: 'button' });
  customElements.define('tiptap-editor-toolbar-mark-button', ToolbarMarkButton, { extends: 'button' });

  // render editors in-page
  document.querySelectorAll<HTMLElement>('[data-tiptap]').forEach(el => {
    const editor = new TiptapEditor(el);
    editor.init();
  });
};

export default {
  init,
};
