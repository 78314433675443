let globalDialogCounter = 0;

/**
 * TODO: Consider refactoring this dialog element to add better exit transitions when new
 * transition-behavior and @starting-style CSS features are globally available on all browsers.
 * See: https://developer.chrome.com/blog/entry-exit-animations/
 */
export class Dialog {
  root: HTMLDialogElement;
  id: string;

  get isOpen() {
    return this.root.hasAttribute('open');
  }

  constructor(root: HTMLDialogElement) {
    this.root = root;
    if (this.root.hasAttribute('init')) return;
    this.root.dataset.init = '';
    this.id = this.root.dataset.dialog || `dialog-${globalDialogCounter++}`;
    if (!this.root.dataset.dialog) this.root.dataset.dialog = this.id;

    // watch dialog attribute changes
    const attrObserver = new MutationObserver(() => {
      if (this.isOpen) {
        this.root.classList.add('open');
      } else {
        this.root.classList.remove('open');
      }
    });
    attrObserver.observe(this.root, {
      attributes: true,
      attributeFilter: ['open']
    });

    // light dismiss
    this.root.addEventListener('click', (evt) => {
      if (evt.target === this.root && this.isOpen) this.root.close();
    });
  }

  toggle = () => {
    if (this.isOpen) this.root.close();
    else this.root.showModal();
  }
}

const initToggleHandler = () => {
  document.body.addEventListener('click', (evt) => {
    const target = evt.target as HTMLElement;
    const toggle = target.hasAttribute('data-dialog-toggle') ? target : target.closest('[data-dialog-toggle]') as HTMLElement;
    if (toggle) {
      const dialogId = toggle.dataset.dialogToggle || toggle.closest<HTMLElement>('dialog[data-dialog]')?.dataset.dialog;
      const dialog = window.UI.dialog.instances.find(d => d.id === dialogId);
      if (!dialog) return;

      dialog.toggle();
    }
  });
}

export const init = () => {
  const dialogs = Array.from(document.querySelectorAll<HTMLDialogElement>('dialog[data-dialog]'));
  if (!window.UI.dialog) {
    window.UI.dialog = { init, instances: [] };
    initToggleHandler();
  }

  for (const root of dialogs) {
    if (!window.UI.dialog.instances.find((x) => x.root === root)) {
      const dialog = new Dialog(root);
      window.UI.dialog.instances.push(dialog);
    }
  }
};

export default {
  init,
};
