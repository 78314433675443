export const init = () => {
  /**
   * Custom scripts for rendering crawled A+ contents properly.
   * Used exclusively for making hero video A+ modules interactive.
   */
  document.body.addEventListener('click', evt => {
    const target = evt.target as HTMLElement;
    const root = target.closest('.video-js');
    if (!target.classList.contains('vjs-control-text') || !root) return;

    root.querySelectorAll('video').forEach(v => v.setAttribute('controls', 'true'));
    target.style.display = 'none';
  });
};

export default {
  init,
};
