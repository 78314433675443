const template = document.createElement('template')
template.innerHTML = `
  <style>
    :host {
      display: inline-block;
      width: 2.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    span {
      box-sizing: border-box;
      display: inline-block;
      line-height: 1;
    }

    [part="track"] {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: rgb(var(--toggle-switch-track-bg, var(--theme-bg-muted)));
      box-shadow: 0 0 0.25rem 1px rgb(var(--theme-border)/50%) inset;
    }

    [part="slider"] {
      padding: 2px;
      height: 100%;
    }

    [part="slider-thumb"] {
      aspect-ratio: 1 / 1;
      height: 100%;
      border-radius: 9999px;
      box-shadow: 0 0 0 1px rgb(var(--toggle-switch-thumb-border, var(--theme-border))) inset;
      background: rgb(var(--theme-bg));
    }

    [part="track"], [part="slider"], [part="slider-thumb"] {
      transition: all 250ms cubic-bezier(1,0,0,1);
    }

    :host(:focus-visible) {
      outline-color: rgb(var(--theme-accent));
      outline-offset: 2px;
      outline-style: solid;
      outline-width: 2px;
      border-radius: 9999px;
    }

    :host([checked]) [part="track"] {
      background-color: rgb(var(--toggle-switch-track-bg-active, var(--theme-accent)));
    }

    :host([checked]) [part="slider"] {
      transform: translateX(66.67%);
    }

    :host([checked]) [part="slider-thumb"] {
      box-shadow: 0 0 0 0 rgb(var(--toggle-switch-thumb-border-active, var(--theme-bg))) inset;
    }

    :host([indeterminate]) [part="track"] {
      background-color: rgb(var(--theme-bg-muted));
      box-shadow: 0 0 0 1px rgb(var(--theme-border)/25%) inset;
    }

    :host([indeterminate]) [part="slider"] {
      transform: translateX(33.33%) scale(0.5);
    }

    :host([indeterminate]) [part="slider-thumb"] {
      background-color: rgb(var(--theme-bg)/50%);
      box-shadow: 0 0 0 2px rgb(var(--theme-border)) inset;
    }
  </style>

  <span part="track">
    <span part="slider">
      <span part="slider-thumb"></span>
    </span>
  </span>
`;

export class UiToggleSwitch extends HTMLElement {
  static formAssociated = true;

  #internals: ElementInternals;

  static get observedAttributes() {
    return ['checked', 'indeterminate'];
  }

  constructor() {
    super();

    this.#internals = this.attachInternals();

    const root = this.attachShadow({ mode: 'open' })
    root.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this.#internals.role = 'switch';
    this.setAttribute('tabindex', '0');
    this.updateState();

    this.addEventListener('click', this.#handleClick);
    this.addEventListener('keydown', this.#handleKeydown);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.#handleClick);
    this.removeEventListener('keydown', this.#handleKeydown);
  }

  attributeChangedCallback(name, _, value) {
    switch (name) {
      case 'checked':
        // disable indeterminate when a checked state is explicitly being set
        if (this.indeterminate) this.indeterminate = false;
        this.updateState();
        break;

      case 'indeterminate':
        // always set toggle to ON when exiting out of indeterminate mode\
        if (value == null && !this.checked) this.checked = true;
        this.updateState();
        break;
    }
  }

  dispatchChangeEvent = () => {
    this.dispatchEvent(new ToggleSwitchChangeEvent('change', { detail: this.value }));
  }

  get checked() { return this.hasAttribute('checked'); }
  set checked(value: boolean) { this.toggleAttribute('checked', value); }

  get indeterminate() { return this.hasAttribute('indeterminate'); }
  set indeterminate(value: boolean) { this.toggleAttribute('indeterminate', value); }

  get value() {
    return this.indeterminate ? null : this.checked ? 'on' : 'off';
  }

  toggle = () => {
    this.checked = !this.checked;
  }

  updateState = () => {
    this.#internals.ariaChecked = this.indeterminate ? 'mixed' : this.checked.toString();
    this.#internals.setFormValue(this.value);
  }

  #handleClick = () => {
    this.toggle();
    this.dispatchChangeEvent();
  }

  #handleKeydown = (evt: KeyboardEvent) => {
    switch (evt.code) {
      case 'Space':
        evt.preventDefault();
        this.toggle();
        this.dispatchChangeEvent();
        break;

      case 'ArrowLeft':
        evt.preventDefault();
        if (this.indeterminate) this.indeterminate = false;
        this.checked = false;
        this.dispatchChangeEvent();
        break;

      case 'ArrowRight':
        evt.preventDefault();
        if (this.indeterminate) this.indeterminate = false;
        this.checked = true;
        this.dispatchChangeEvent();
        break;
    }
  }
}

export class ToggleSwitchChangeEvent extends CustomEvent<'on' | 'off' | null> { }
