import * as Plot from '@observablehq/plot';

export default class ProductPurchaseOrdersTimeline extends HTMLElement {
  _purchaseOrders = [];

  set purchaseOrders(pos) {
    this._purchaseOrders = pos;
    this.draw();
  }

  constructor() {
    super();
  }

  draw = () => {
    const graph = this;
    const purchaseOrders = this._purchaseOrders;

    if (purchaseOrders.length === 0) {
      graph.innerHTML = `
        <div class="min-h-24 flex items-center justify-center text-text/50 italic">
          No purchase orders placed within this time frame.
        </div>
      `;
      return;
    }

    // calculate axis-mappable dates & attributes
    for (const po of purchaseOrders) {
      po.window_start_date = new Date(po.window_start);
      po.window_end_date = new Date(new Date(po.window_end).getTime() + (1000 * 60 * 60 * 24));
      po.window_mid_date = new Date(po.window_start_date.getTime() + ((po.window_end_date - po.window_start_date) / 2));
      po.order_date_date = new Date(po.order_date);

      if (po.status === 'open') {
        po.color = 'rgb(var(--theme-status-success)/50%)';
        po.fillColor = 'rgb(var(--theme-status-success)/10%)';
      } else if (po.status === 'confirmed') {
        po.color = 'rgb(var(--theme-status-success))';
        po.fillColor = 'rgb(var(--theme-status-success)/20%)';
      } else if (po.status === 'closed') {
        po.color = 'rgb(var(--theme-gray-500))';
        po.fillColor = 'rgb(var(--theme-gray-500)/20%)';
      } else if (po.status === 'pending_cancellation') {
        po.color = 'rgb(var(--theme-status-critical)/50%)';
        po.fillColor = 'rgb(var(--theme-status-critical)/10%)';
      } else if (po.status === 'cancelled') {
        po.color = 'rgb(var(--theme-status-critical))';
        po.fillColor = 'rgb(var(--theme-status-critical)/20%)';
      } else if (po.status === 'closed') {
        po.color = 'rgb(var(--theme-gray-500))';
        po.fillColor = 'rgb(var(--theme-gray-500)/20%)';
      }
    }

    // sort in reverse so we chart older entries first
    purchaseOrders.sort((x, y) => x.order_date_date - y.order_date_date);

    // calculate y values
    let yStack = [];
    for (const po of purchaseOrders) {
      // remove all stack entries that are past this PO's dot_x range
      yStack = yStack.filter(x => x.window_end_date.getTime() + (1000 * 60 * 60 * 24) > po.order_date_date.getTime());

      let i = 0, appended = false;
      while (!appended) {
        if (yStack.find(x => x.y === i) === undefined) {
          po.y = i;
          po.y1 = i - 0.4;
          po.y2 = i + 0.4;
          yStack.push(po);
          appended = true;
        }
        i++;
      }
    }

    // aggregate crosshair-highlightable dates
    const crosshairDates = [];
    for (const po of purchaseOrders) {
      crosshairDates.push({ date: po.order_date_date });
      crosshairDates.push({ date: po.window_start_date });
      crosshairDates.push({ date: po.window_end_date });
    }

    // calculate chart height based on total number of stacked entries
    const maxY = Math.max(...purchaseOrders.map(x => x.y)) + 1;
    const heightMultiplier = maxY > 20 ? 10 : maxY > 15 ? 15 : maxY > 10 ? 20 : maxY > 5 ? 32 : 40;
    const height = (maxY * heightMultiplier) + 26; // offset for bottom axis

    const box = graph.getBoundingClientRect();
    const plot = Plot.plot({
      style: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '0.7rem',
      },
      width: box.width,
      height: height,
      x: {
        axis: 'bottom',
        grid: true,
        nice: true,
      },
      y: {
        axis: null,
        reverse: true,
      },
      marks: [
        Plot.crosshairX(crosshairDates, {
          x: 'date',
        }),
        Plot.ruleY(purchaseOrders, {
          x1: 'order_date_date',
          x2: 'window_start_date',
          y: 'y',
          stroke: 'rgb(var(--theme-border))',
        }),
        Plot.dot(purchaseOrders, {
          x: 'order_date_date',
          y: 'y',
          stroke: null,
          fill: 'color',
        }),
        Plot.rectX(purchaseOrders, {
          x1: 'window_start_date',
          x2: 'window_end_date',
          y1: 'y1',
          y2: 'y2',
          fill: 'fillColor',
        }),
        Plot.ruleX(purchaseOrders, {
          x: 'window_start_date',
          y1: 'y1',
          y2: 'y2',
          stroke: 'color',
        }),
        Plot.ruleX(purchaseOrders, {
          x: 'window_end_date',
          y1: 'y1',
          y2: 'y2',
          stroke: 'color',
        }),
        Plot.tip(
          purchaseOrders,
          Plot.pointer({
            x: 'window_mid_date',
            y: 'y',
            channels: {
              po: { value: 'po', label: 'PO' },
              status: { value: 'status', label: 'Status' },
              ship_to_location: { value: 'ship_to_location', label: 'Ship to Location' },
              order_date: { value: 'order_date', label: 'Order Date' },
              window_type: { value: 'window_type', label: 'Window Type' },
              window_start: { value: 'window_start', label: 'Window Start' },
              window_end: { value: 'window_end', label: 'Window End' },
              quantity_requested: { value: 'quantity_requested', label: 'Qty. Requested' },
              quantity_accepted: { value: 'quantity_accepted', label: 'Qty. Accepted' },
              quantity_received: { value: 'quantity_received', label: 'Qty. Received' },
              quantity_cancelled: { value: 'quantity_cancelled', label: 'Qty. Cancelled' },
            },
            format: {
              x: false,
              y: false,
            }
          }),
        )
      ]
    });
    plot.style.overflow = 'visible';

    while (graph.firstChild) graph.removeChild(graph.firstChild);
    graph.appendChild(plot);
  }

  connectedCallback() {
    this.draw();
    window.addEventListener('resize', this.draw);
    window.addEventListener('ui:tab:activate', this.draw);
  }

  disconnectedCallback() {
    //
  }

  adoptedCallback() {
    this.draw();
  }

  attributeChangedCallback() {
    this.draw();
  }
}
