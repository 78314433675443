import { Forecast, ObjectWithDate, WeekToForecast } from 'types/components/forecasts';
import { parse, startOfWeek } from 'date-fns';

function compareDates(a: ObjectWithDate, b: ObjectWithDate, key: string) {
  return a[key].getTime() - b[key].getTime();
}

// Combines two arrays by the key specified by the prop parameter
// Source: https://www.30secondsofcode.org/js/s/combine-object-arrays/
const combineLeftByDate = (a: any[], b: any[], prop: string, includeProp: string) =>
  a.map((element) => {
    const index = b.findIndex((item) => {
      if (item[prop] && element[prop]) {
        return item[prop].getTime() === element[prop].getTime();
      }
      return false;
    });
    if (index >= 0) {
      return {
        ...element,
        [includeProp]: b[index][includeProp],
      };
    }
    return {
      ...element,
      [includeProp]: null,
    };
  });

class ProductForecastsTableDataProvider {
  now = new Date();
  _forecastReports = [];
  _salesReports = [];
  _data = [];

  subscribers: ((data: any) => void)[] = [];

  set reports(reports) {
    this._forecastReports = reports.forecasts;
    this._salesReports = reports.sales;
    this.processReports();
    for (const callback of this.subscribers) {
      callback(this._data);
    }
  }

  subscribe = (callback: (data: any) => void) => {
    this.subscribers.push(callback);
  };

  processReports = () => {
    const forecastReports = this._forecastReports;
    const salesReports = this._salesReports;
    var self = this;

    const parsedReports = forecastReports
      .map((r) => ({
        ...r,
        report_beginning_on: parse(r.report_beginning_on, 'yyyy-MM-dd HH:mm:ss', self.now, { weekStartsOn: 0 }),
        first_week_beginning_on: parse(r.first_week_beginning_on, 'yyyy-MM-dd HH:mm:ss', self.now, { weekStartsOn: 0 }),
        forecasts: self.processForecastRow(JSON.parse(r.forecasts)),
      }))
      .sort((a, b) => compareDates(a, b, 'report_beginning_on'));

    const forecasts = parsedReports
      .reduce((prevValue, currentReport) => {
        const forecasts = currentReport.forecasts.map((f) => ({
          ...f,
          forecastedOn: currentReport.report_beginning_on,
        }));
        return [...prevValue, ...forecasts];
      }, [])
      .sort((a, b) => -compareDates(a, b, 'forecastedOn') || -compareDates(a, b, 'week'));

    const sales = this.processSales(salesReports);
    this._data = combineLeftByDate(forecasts, sales, 'week', 'shippedUnits');
  };

  processForecastRow = (forecasts: Forecast): WeekToForecast[] => {
    const weekToForecasts = [];
    for (const yearKey in forecasts) {
      // Year keys follow the format: 'y2023'
      const year = yearKey[0] === 'y' ? parseInt(yearKey.slice(1)) : parseInt(yearKey);
      // Key can either be the month like 'Jan' or the week number like 'w1'
      for (const key in forecasts[yearKey]) {
        const firstDayOfYear = startOfWeek(new Date(year, 1, 1), { weekStartsOn: 0 });
        if (key[0] === 'w') {
          const weekStart = parse(key.slice(1), 'w', firstDayOfYear, { weekStartsOn: 0 });
          weekToForecasts.push({
            week: weekStart,
            p90: forecasts[yearKey][key]['p90'],
            p80: forecasts[yearKey][key]['p80'],
            p70: forecasts[yearKey][key]['p70'],
            mean: forecasts[yearKey][key]['mean'],
          });
        }
      }
    }
    return weekToForecasts;
  };

  processSales = (sales) => {
    return sales.map((r) => ({
      week: parse(r.beginning_on, 'yyyy-MM-dd', this.now, { weekStartsOn: 0 }),
      shippedUnits: r.shipped_units,
    }));
  };
}

export default ProductForecastsTableDataProvider;
