export class UiFileDropper extends HTMLElement {
  input: HTMLInputElement;
  dropZone: HTMLElement;
  dropZoneOriginalText = '';

  connectedCallback() {
    this.input = this.querySelector('input[type=file]');
    this.dropZone = this.querySelector('.drop-zone');
    this.dropZoneOriginalText = this.dropZone.innerText.trim();

    this.addEventListener('click', this.onClick);
    this.addEventListener('dragenter', this.onDragEnter);
    this.addEventListener('dragover', this.onDragOver);
    this.addEventListener('dragleave', this.onDragLeave);
    this.addEventListener('drop', this.onDrop);
  }

  disconnectedCallback() {
    this.addEventListener('click', this.onClick);
    this.removeEventListener('dragenter', this.onDragEnter);
    this.removeEventListener('dragover', this.onDragOver);
    this.removeEventListener('dragleave', this.onDragLeave);
    this.removeEventListener('drop', this.onDrop);
  }

  onClick = () => {
    this.input.click();
  }

  onDragEnter = (evt: DragEvent) => {
    evt.preventDefault();
    this.classList.add('drag-active');
    this.dropZone.innerText = 'Drop files here!';
  }

  onDragOver = (evt: DragEvent) => {
    evt.preventDefault();
  }

  onDragLeave = (evt: DragEvent) => {
    evt.preventDefault();
    this.classList.remove('drag-active');
    this.dropZone.innerText = this.dropZoneOriginalText;
  }

  onDrop = async (evt: DragEvent) => {
    this.classList.remove('drag-active');
    this.dropZone.innerText = this.dropZoneOriginalText;
    evt.preventDefault();
  }
}
