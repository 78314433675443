import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductPurchaseOrders {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.beginningOn = this.$table.attr('data-beginning-on');
    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#product-purchase-orders-date')
      .daterangepicker(
        {
          showWeekNumbers: true,
          startDate: moment().subtract(90, 'days'),
          endDate: moment(),
          opens: 'left',
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.reload((json) => {
            this.updateGraph(json.data);
          });
        },
      )
      .data('daterangepicker');
  }

  initLocationFilter() {
    this.locationFilter = $('#product-purchase-orders-location');

    this.locationFilter.on('change', () => {
      const targets = [];
      this.locationFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.tableDefinition.columnIndex('ship_to_location')).search(`^(${regex})$`, true, false).draw();
      window.dt = this.dataTable;
    });
  }

  initStatusFilter() {
    this.statusFilter = $('#product-purchase-orders-status');

    this.statusFilter.on('change', () => {
      const targets = [];
      this.statusFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.tableDefinition.columnIndex('status')).search(`^(${regex})$`, true, false).draw();
    });
  }

  updateFilterOptions(data) {
    // location filter
    const locations = _.uniq(data.map(x => x.ship_to_location));
    const locationOptions = locations.map(x => {
      return `<option value="${x}" selected>${x}</option>`;
    });
    locationOptions.sort();
    this.locationFilter.html(locationOptions.join(''));

    // status filter
    const statuses = _.uniq(data.map(x => x.status));
    const statusOptions = statuses.map(x => {
      return `<option value="${x}" selected>${x}</option>`;
    });
    this.statusFilter.html(statusOptions.join(''));
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['order_date'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'order_date',
          data: 'order_date',
          render: this.helpers.renderDate,
        },
        {
          name: 'status',
          data: 'status',
          render: (data) => {
            const status = (data || '').toString().toLocaleLowerCase().replace(/(\s+|-)/g, '_');
            return `<span class="status-badge ${status}">${data}</span>`;
          }
        },
        {
          name: 'po',
          data: 'po',
        },
        {
          name: 'vendor_code',
          data: 'vendor_code',
        },
        {
          name: 'ship_to_location',
          data: 'ship_to_location',
        },
        {
          name: 'availability',
          data: 'availability',
        },
        {
          name: 'backordered',
          data: 'backordered',
        },
        {
          name: 'window_type',
          data: 'window_type',
        },
        {
          name: 'window_start',
          data: 'window_start',
        },
        {
          name: 'window_end',
          data: 'window_end',
        },
        {
          name: 'expected_date',
          data: 'expected_date',
        },
        {
          name: 'pending_cancellation_date',
          data: 'pending_cancellation_date',
        },
        {
          name: 'cancelled_date',
          data: 'cancelled_date',
        },
        {
          name: 'quantity_requested',
          data: 'quantity_requested',
        },
        {
          name: 'quantity_accepted',
          data: 'quantity_accepted',
        },
        {
          name: 'quantity_received',
          data: 'quantity_received',
        },
        {
          name: 'quantity_outstanding',
          data: 'quantity_outstanding',
        },
        {
          name: 'quantity_cancelled',
          data: 'quantity_cancelled',
        },
        {
          name: 'cancellation_reason',
          data: 'cancellation_reason',
        },
        {
          name: 'case_size',
          data: 'case_size',
        },
        {
          name: 'case_cost_cents',
          data: 'case_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'total_cost_cents',
          data: 'total_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'cost_price_cents',
          data: 'cost_price_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'accepted_cost_cents',
          data: 'accepted_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'cancelled_cost_cents',
          data: 'cancelled_cost_cents',
          render: this.helpers.renderDollar,
        },
      ],
    });
  }

  updateGraph(purchaseOrders) {
    const timeline = document.querySelector('#product-purchase-orders-timeline');
    if (timeline) timeline.purchaseOrders = purchaseOrders;

    const funnel = document.querySelector('#product-purchase-orders-funnel');
    if (funnel) funnel.purchaseOrders = purchaseOrders;
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: this.filename,
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        url: this.ajaxUrl,
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: (json) => {
          const data = json.data;
          this.updateFilterOptions(data);
          this.tableData = data;
          return data;
        },
      },
      columnDefs: [
        {
          defaultContent: '-',
          targets: '_all',
        },
      ],
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('purchase orders'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.updateGraph(json.data);
    });

    this.dataTable.on('search.dt', () => {
      const filteredData = this.dataTable.rows({ filter: 'applied' }).data();
      this.updateGraph(Array.from(filteredData));
    });
  }

  init() {
    this.initDatePicker();
    this.initLocationFilter();
    this.initStatusFilter();
    this.defineTable();
    this.initTable();
  }
}

export default ProductPurchaseOrders;
