const getRegion = () => {
  return document.querySelector('#organization-data')?.getAttribute('data-region');
}

const getCurrency = () => {
  return document.querySelector('#organization-data')?.getAttribute('data-currency');
}

const getCurrencySymbol = () => {
  return document.querySelector('#organization-data')?.getAttribute('data-currency-symbol');
}

const getCurrencyNamePlural = () => {
  return document.querySelector('#organization-data')?.getAttribute('data-currency-name-plural');
}

export const regionsHelper = {
  getRegion,
  getCurrency,
  getCurrencySymbol,
  getCurrencyNamePlural,
};
