import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductsMonitored {
  constructor(rootSelector) {
    this.helpers = renderHelpers;
    this.$table = $(rootSelector);
  }

  set_column_name() {
    this.$table = $('#monitored-products-table');
  }

  init() {
    const self = this;

    this.set_column_name();

    var definition = new DataTableDefinition({
      defaultSortByColumns: ['asin'],
      defaultSortByDirections: ['asc'],
      columns: [
        {
          name: 'asin',
          data: 'asin',
          className: 'numeric',
          render: self.helpers.renderAsinLink,
        },
        {
          name: 'main_image_thumb',
          data: 'main_image_thumb',
          class: 'w-16 px-1',
          sortable: false,
          render: self.helpers.renderProductThumbnail,
        },
        {
          name: 'asin_name',
          data: function (row, type, val, meta) {
            return (
              row.asin_name || row.amazon_product_title || '<span class="italic text-text/50">No name found</span>'
            );
          },
          className: 'min-w-96',
          render: self.helpers.renderProductName,
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          className: 'min-w-48',
          render: self.helpers.renderTag,
        },
        {
          name: 'average_customer_review',
          data: 'average_customer_review',
          className: 'min-w-48',
          render: self.helpers.renderProductReview,
        },
        {
          name: 'image_count',
          data: 'image_count',
          sortable: false,
          render: self.helpers.renderCheckmarkCount,
        },
        {
          name: 'feature_bullets_count',
          data: 'feature_bullets_count',
          sortable: false,
          render: self.helpers.renderCheckmark,
        },
        {
          name: 'product_overview_count',
          data: 'product_overview_count',
          sortable: false,
          render: self.helpers.renderCheckmark,
        },
        {
          name: 'a_plus',
          data: 'a_plus',
          sortable: false,
          render: self.helpers.renderCheckmark,
        },
        {
          name: 'has_product_description',
          data: 'has_product_description',
          sortable: false,
          render: self.helpers.renderCheckmark,
        },
        {
          name: 'variations_count',
          data: 'variations_count',
          sortable: false,
          render: self.helpers.renderCheckmarkCount,
        },
        {
          name: 'amazon_choice_for',
          data: 'amazon_choice_for',
          class: 'min-w-48',
        },
        {
          name: 'best_seller_in',
          data: 'best_seller_in',
          class: 'min-w-48',
        },
      ],
    });

    const buttons = [{
      extend: 'ilColvis',
      columns: definition.columnIndexes([
        'asin',
        'asin_name',
        'tag_name',
        'average_customer_review',
        'image_count',
        'feature_bullets_count',
        'product_overview_count',
        'a_plus',
        'has_product_description',
        'variations_count',
        'amazon_choice_for',
        'best_seller_in',
      ]),
    }];

    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsvServer',
        monitored: true,
        url: TORO.shared.urlHostPathname().replace('/monitored', '') + '/datatables.csv',
      });
    }

    var table = this.$table.DataTable({
      serverSide: true,
      processing: true,
      info: true,
      ajax: {
        data: (d) => {
          // fetch only monitored products
          d['monitored'] = true;
        },
        url: TORO.shared.urlHostPathname().replace('/monitored', '') + '/datatables',
        type: 'POST',
        error: function (xhr, error, thrown) {
          // Handle requests that are being cancelled because of refresh / unloading
          // https://stackoverflow.com/questions/699941/handle-ajax-error-when-a-user-clicks-refresh/18170879#18170879
          // Various solutions. For now just ignore, since its typically a false
          // positive right now...
        },
      },
      paging: true,
      pageLength: 25,
      dom: "<'dataTables_header border-b-0'fB><'dataTables_body border-b border-border'i><'dataTables_body'tr><'dataTables_footer'p>",
      bAutoWidth: false,
      deferRender: true,
      language: TORO.shared.dataTableDefaults.language('monitored products'),
      buttons: buttons,
      columns: definition.columns,
    });

    // Add support for monitored product filters
    const filters = {
      image_count: 'products-images-count-filter',
      feature_bullets_count: 'products-feature-bullets-filter',
      product_overview_count: 'products-product-overview-filter',
      a_plus: 'products-aplus-filter',
      has_product_description: 'products-product-description-filter',
      variations_count: 'products-variations-count-filter',
    };

    for (const [key, id] of Object.entries(filters)) {
      const el = document.getElementById(id);
      if (!el) continue;

      el.addEventListener('change', evt => {
        const value = evt.target.value || '';
        const colIndex = definition.columnIndex(key);
        table.column(colIndex).search(value, false, false).draw();
      });
    }
  }
}

export default ProductsMonitored;
