import { DateRange, Forecast, ObjectWithDate, ProductForecastStatistic } from 'types/components/forecasts';
import { addWeeks, endOfYear, parse, startOfWeek, startOfYear } from 'date-fns';
import ProductForecastsLine from 'components/charts/product-forecasts-line';

function compareDates(a: ObjectWithDate, b: ObjectWithDate, key: string) {
  return a[key].getTime() - b[key].getTime();
}

const STATISTICS: ProductForecastStatistic[] = ['p90', 'p80', 'p70', 'mean'];

class ProductForecastsDataProvider {
  now = new Date();
  dateRange: DateRange = {
    start: startOfWeek(startOfYear(this.now), { weekStartsOn: 0 }),
    end: startOfWeek(startOfYear(this.now.setFullYear(this.now.getFullYear() + 1)), { weekStartsOn: 0 }),
  };

  allDates = [];
  _forecastReports = [];
  _chartData = [];
  _forecastPoints = [];

  subscribedComponents: ProductForecastsLine[] = [];

  set year(newYear: number) {
    this.dateRange = {
      start: startOfWeek(startOfYear(new Date(newYear, 0, 1)), { weekStartsOn: 0 }),
      end: startOfWeek(endOfYear(new Date(newYear, 0, 1)), { weekStartsOn: 0 }),
    };
    let currentDate = this.dateRange.start;
    this.allDates = [];
    while (currentDate <= this.dateRange.end) {
      this.allDates.push(currentDate);
      currentDate = addWeeks(currentDate, 1);
    }
    this.processForecastReports();
    for (const component of this.subscribedComponents) {
      component.data = this._chartData;
      component.forecastPoints = this._forecastPoints;
    }
  }

  set forecastReports(reports) {
    this._forecastReports = reports;
    this.processForecastReports();
    for (const component of this.subscribedComponents) {
      component.data = this._chartData;
      component.forecastPoints = this._forecastPoints;
    }
  }

  constructor() {
    let currentDate = this.dateRange.start;
    while (currentDate <= this.dateRange.end) {
      this.allDates.push(currentDate);
      currentDate = addWeeks(currentDate, 1);
    }
  }

  subscribeComponent(component) {
    this.subscribedComponents.push(component);
  }

  processForecastReports = () => {
    const forecastReports = this._forecastReports;

    // Sort the forecast reports by date
    // For each date in the year, get the corresponding
    // values for latest, min, max
    const parsedReports = forecastReports
      .map((r) => ({
        ...r,
        first_week_beginning_on: parse(r.first_week_beginning_on, 'yyyy-MM-dd HH:mm:ss', this.now, { weekStartsOn: 0 }),
        forecasts: this.processForecastRow(JSON.parse(r.forecasts), this.dateRange),
      }))
      .sort((a, b) => compareDates(a, b, 'first_week_beginning_on'));

    this._forecastPoints = [];
    for (const report of parsedReports) {
      for (const forecast of report.forecasts) {
        this._forecastPoints.push({
          forecasted_on: report.first_week_beginning_on,
          ...forecast
        });
      }
    }

    this._chartData = this.allDates.map((weekStart) => {
      const forecastsContainingWeek = this.getForecastsContainingWeek(parsedReports, weekStart);
      if (forecastsContainingWeek.length > 0) {
        const infoByStatistic = {};

        for (const statistic of STATISTICS) {
          const min = this.getMinForecastForWeek(forecastsContainingWeek, weekStart, statistic);
          const max = this.getMaxForecastForWeek(forecastsContainingWeek, weekStart, statistic);
          const latest = this.getLatestForecastForWeek(forecastsContainingWeek, weekStart, statistic);
          infoByStatistic[statistic] = {
            min,
            max,
            latest,
          };
        }

        return {
          week: weekStart,
          ...infoByStatistic,
        };
      }

      const infoByStatistic = {};
      for (const statistic of STATISTICS) {
        infoByStatistic[statistic] = {
          min: null,
          max: null,
          latest: null,
        };
      }

      return {
        week: weekStart,
        ...infoByStatistic,
      };
    });
  };

  getForecastsContainingWeek = (reports, weekStart: Date) => {
    return reports.filter((report) => !!report.forecasts.find((f) => f.week.getTime() === weekStart.getTime()));
  };

  getForecastForWeek(report, week: Date) {
    return report.forecasts.find((f) => f.week.getTime() === week.getTime());
  }

  getLatestForecastForWeek = (reports, weekStart: Date, statistic: ProductForecastStatistic) => {
    if (reports.length < 1) {
      return null;
    }

    const sortedReports = [...reports].sort((a, b) => compareDates(b, a, 'first_week_beginning_on'));
    return this.getForecastForWeek(sortedReports[0], weekStart)[statistic];
  };

  getMinForecastForWeek = (reports, weekStart: Date, statistic: ProductForecastStatistic) => {
    if (reports.length < 1) {
      return null;
    }

    return reports.map((r) => this.getForecastForWeek(r, weekStart)).sort((a, b) => a[statistic] - b[statistic])[0][
      statistic
    ];
  };

  getMaxForecastForWeek = (reports, weekStart: Date, statistic: ProductForecastStatistic) => {
    if (reports.length < 1) {
      return null;
    }

    return reports.map((r) => this.getForecastForWeek(r, weekStart)).sort((a, b) => b[statistic] - a[statistic])[0][
      statistic
    ];
  };

  processForecastRow = (forecasts: Forecast, acceptableDateRange: DateRange): { weekStart: Date; units: number }[] => {
    const weekToForecasts = [];
    for (const yearKey in forecasts) {
      // Year keys follow the format: 'y2023'
      const year = parseInt(yearKey.slice(1));
      // Key can either be the month like 'Jan' or the week number like 'w1'
      for (const key in forecasts[yearKey]) {
        const firstDayOfYear = startOfWeek(startOfYear(new Date(year, 0, 1)), { weekStartsOn: 0 });
        if (key[0] === 'w') {
          const weekStart = parse(key.slice(1), 'w', firstDayOfYear, { weekStartsOn: 0 });
          if (weekStart >= acceptableDateRange.start && weekStart <= acceptableDateRange.end) {
            weekToForecasts.push({
              week: weekStart,
              p90: forecasts[yearKey][key]['p90'],
              p80: forecasts[yearKey][key]['p80'],
              p70: forecasts[yearKey][key]['p70'],
              mean: forecasts[yearKey][key]['mean'],
            });
          }
        }
      }
    }
    return weekToForecasts;
  };
}

export default ProductForecastsDataProvider;
