import { computePosition, flip, offset } from '@floating-ui/dom';

export type DropdownOption = {
  text: string;
  value: string;
  content: string;
  disabled: boolean;
};

export type DropdownOptionGroup = {
  label: string;
  options: DropdownOption[];
};

export class Dropdown {
  root: HTMLElement;
  select: HTMLSelectElement;
  multiple: boolean;
  disabled: boolean;
  searchInput: HTMLInputElement;
  options: (DropdownOption | DropdownOptionGroup)[] = [];
  dropdown: HTMLElement;
  dropdownOptions: HTMLElement;
  dropdownControls?: HTMLElement;

  keyboardFocus = -1;

  constructor(root: HTMLElement) {
    this.root = root;

    const select = root.querySelector('select');
    if (!select) throw new Error('[ui:dropdown] Dropdown element has no <select> tag.');
    this.select = select;
    this.select.tabIndex = -1;
    this.multiple = select.multiple;
    this.disabled = select.disabled;

    // visible input for searching options
    this.searchInput = document.createElement('input');
    this.searchInput.type = 'text';
    // this.searchInput.id = select.id;
    this.searchInput.name = select.name.replace(/[\[\]]/g, '-') + '--q';
    this.searchInput.placeholder = root.dataset.dropdownPlaceholder || '';
    this.searchInput.setAttribute('data-1p-ignore', '');
    this.searchInput.disabled = this.disabled;

    // parse <option> tags from select
    this.parseOptions();

    // build schema for popup with options
    this.dropdown = document.createElement('div');
    this.dropdown.className = 'dropdown-popup';
    this.dropdown.setAttribute('data-hide', '');

    if (this.multiple) {
      this.dropdownControls = document.createElement('div');
      this.dropdownControls.className = 'dropdown-controls';
      const buttons = document.createElement('div');
      buttons.className = 'button-group';

      const selectAll = document.createElement('button');
      selectAll.type = 'button';
      selectAll.className = 'button secondary';
      selectAll.innerText = 'All';
      selectAll.addEventListener('mousedown', this.selectAll);

      const selectNone = document.createElement('button');
      selectNone.type = 'button';
      selectNone.className = 'button secondary bordered';
      selectNone.innerText = 'None';
      selectNone.addEventListener('mousedown', this.selectNone);

      buttons.appendChild(selectAll);
      buttons.appendChild(selectNone);
      this.dropdownControls.appendChild(buttons);
      this.dropdown.appendChild(this.dropdownControls);
    }

    this.dropdownOptions = document.createElement('div');
    this.dropdownOptions.className = 'dropdown-options';
    this.dropdown.appendChild(this.dropdownOptions);

    this.updateOptions();
    this.updateState();

    // set up a mutation observer on the original select box to keep track of external option changes
    const observer = new MutationObserver(() => {
      this.parseOptions();
      this.updateOptions();
      this.updateState();
    });
    observer.observe(this.select, { childList: true, subtree: true, attributes: true, characterData: true });

    // switch select with input set
    root.appendChild(this.searchInput);

    // render the popup
    document.body.appendChild(this.dropdown);

    // register popup & related events
    this.repositionPopup();

    this.root.addEventListener('focusin', this.show);
    this.root.addEventListener('focusout', this.hide);
    this.select.addEventListener('focus', () => {
      // when the focus is set by the browser's validator on the select box directly,
      // we pass it back onto the search input
      this.searchInput.focus();
    });
    this.select.addEventListener('change', () => {
      this.updateState();
      this.updateOptions();
    });
    this.searchInput.addEventListener('click', this.show);
    this.searchInput.addEventListener('input', this.updateOptions);
    this.searchInput.addEventListener('keydown', this.onInputKeydown);
    this.dropdown.addEventListener('mousedown', this.onDropdownClick);
  }

  repositionPopup = async () => {
    const { x, y } = await computePosition(this.searchInput, this.dropdown, {
      placement: 'bottom-start',
      middleware: [
        flip(),
        offset(5),
      ]
    });

    Object.assign(this.dropdown.style, {
      left: `${x}px`,
      top: `${y}px`,
      minWidth: `${this.searchInput.clientWidth}px`,
    });
  }

  show = () => {
    this.dropdown.removeAttribute('data-hide');
    this.repositionPopup();
  };

  hide = async () => {
    this.dropdown.setAttribute('data-hide', '');
  };

  parseOptions = () => {
    this.options = [];
    for (const child of Array.from(this.select.children)) {
      if (child.tagName.toLocaleLowerCase() === 'option') {
        const opt = child as HTMLOptionElement;
        this.options.push({
          text: opt.innerText.trim(),
          value: opt.value,
          content: opt.dataset.content || opt.innerText.trim(),
          disabled: opt.disabled,
        });
      } else if (child.tagName.toLocaleLowerCase() === 'optgroup') {
        const optgroup = child as HTMLOptGroupElement;
        const group: DropdownOptionGroup = { label: optgroup.label, options: [] };
        optgroup.querySelectorAll('option').forEach((opt) => {
          group.options.push({
            text: opt.innerText.trim(),
            value: opt.value,
            content: opt.dataset.content || opt.innerText.trim(),
            disabled: opt.disabled,
          });
        });
        this.options.push(group);
      }
    }
  };

  updateOptions = () => {
    const query = this.searchInput.value.toLocaleLowerCase();
    const filteredOptions = query ? [] : this.options;

    if (query) {
      // comparison helper
      const compare = (opt: DropdownOption) => {
        return opt.text.toLocaleLowerCase().includes(query) || opt.value.toLocaleLowerCase().includes(query);
      };

      // filter nested options
      for (const opt of this.options) {
        if ('options' in opt) {
          const group: DropdownOptionGroup = { label: opt.label, options: [] };
          for (const o of opt.options) {
            if (compare(o)) group.options.push(o);
          }
          if (group.options.length > 0) filteredOptions.push(group);
        } else {
          if (compare(opt)) filteredOptions.push(opt);
        }
      }
    }

    while (this.dropdownOptions.lastChild) this.dropdownOptions.removeChild(this.dropdownOptions.lastChild);

    for (const opt of filteredOptions) {
      if ('options' in opt) {
        const label = document.createElement('div');
        label.className = 'optgroup-label';
        label.innerText = opt.label;
        this.dropdownOptions.appendChild(label);

        for (const o of opt.options) {
          this.dropdownOptions.appendChild(this.createOption(o));
        }
      } else {
        this.dropdownOptions.appendChild(this.createOption(opt));
      }
    }

    // append empty-state placeholder
    if (this.dropdownOptions.children.length === 0) {
      const empty = document.createElement('div');
      empty.className = 'dropdown-options-empty';
      empty.innerText = this.root.dataset.dropdownEmptyText || 'No options found.';
      this.dropdownOptions.appendChild(empty);
    }

    this.repositionPopup();
  };

  createOption = (opt: DropdownOption) => {
    const option = document.createElement('button');
    option.className = 'dropdown-option';

    if (this.multiple) {
      const activeOpt = this.select.querySelector<HTMLOptionElement>(`option[value="${opt.value}"]`);
      if (activeOpt?.selected) option.classList.add('selected');
    } else {
      if (opt.value && opt.value === this.select.value) option.classList.add('selected');
    }

    option.value = opt.value;
    if (opt.disabled) option.disabled = true;
    option.innerHTML = opt.content;
    option.tabIndex = -1;
    return option;
  };

  onInputKeydown = (evt: KeyboardEvent) => {
    const opts = Array.from(this.dropdownOptions.querySelectorAll<HTMLButtonElement>('button:not(:disabled)'));
    if (evt.key === 'ArrowDown') {
      this.show();
      this.keyboardFocus++;
      if (this.keyboardFocus >= opts.length) this.keyboardFocus = 0;
    } else if (evt.key === 'ArrowUp') {
      this.show();
      this.keyboardFocus--;
      if (this.keyboardFocus < 0) this.keyboardFocus = opts.length - 1;
    } else if (evt.key === 'Enter') {
      this.keyboardFocus = -1;
      const currentFocus = this.dropdownOptions.querySelector<HTMLButtonElement>('button.keyboard-focused');
      const target = currentFocus || (opts.length === 1 ? opts[0] : null);
      if (target && !target.disabled) this.setValue(target.value);
    } else if (evt.key === 'Escape') {
      this.keyboardFocus = -1;
      this.hide();
    }

    const currentFocus = this.dropdownOptions.querySelector('button.keyboard-focused');
    if (currentFocus) currentFocus.classList.remove('keyboard-focused');

    // scroll dropdown item into view
    if (this.keyboardFocus >= 0 && this.keyboardFocus < opts.length) {
      opts[this.keyboardFocus].classList.add('keyboard-focused');
      opts[this.keyboardFocus].scrollIntoView({ block: 'nearest' });
    }
  };

  onDropdownClick = (evt: MouseEvent) => {
    const button = evt.target as HTMLButtonElement;
    if (button.classList.contains('dropdown-option') && !button.disabled) {
      this.setValue(button.value);
      evt.preventDefault();
    }
  };

  setValue = (value: string) => {
    if (this.disabled) return;

    this.select.click();

    if (this.multiple) {
      const opt = this.select.querySelector<HTMLOptionElement>(`option[value="${value}"]`);
      if (opt) opt.selected = !opt.selected;
    } else {
      this.select.value = value;
    }

    this.select.dispatchEvent(new Event('change'));
    this.updateState();

    this.searchInput.value = '';
    this.updateOptions();

    if (!this.multiple) this.hide();
  };

  selectAll = (evt: MouseEvent) => {
    if (!this.multiple) return;
    evt.preventDefault();

    this.select.querySelectorAll('option').forEach(opt => {
      if (!opt.disabled) opt.selected = true;
    });

    this.select.dispatchEvent(new Event('change'));
    this.updateState();

    this.searchInput.value = '';
    this.updateOptions();
  }

  selectNone = (evt: MouseEvent) => {
    if (!this.multiple) return;
    evt.preventDefault();

    this.select.querySelectorAll('option').forEach(opt => {
      if (!opt.disabled) opt.selected = false;
    });

    this.select.dispatchEvent(new Event('change'));
    this.updateState();

    this.searchInput.value = '';
    this.updateOptions();
  }

  getOptionText = (value: string) => {
    for (const x of this.options) {
      if ('options' in x) {
        for (const y of x.options) {
          if (y.value === value) return y.text;
        }
      } else {
        if (x.value === value) return x.text;
      }
    }

    return null;
  };

  updateState = () => {
    if (this.select.value) this.root.setAttribute('data-dropdown-filled', '');
    else this.root.removeAttribute('data-dropdown-filled');

    this.disabled = this.select.disabled;
    this.searchInput.disabled = this.disabled;

    // set placeholder text
    if (this.multiple && this.select.selectedOptions.length > 0) {
      const optTexts = Array.from(this.select.selectedOptions).map((opt) => this.getOptionText(opt.value));
      this.searchInput.placeholder = optTexts.join(', ');
    } else if (!this.multiple && this.select.value) {
      const text = this.getOptionText(this.select.value);
      this.searchInput.placeholder = text || this.select.value;
    } else {
      this.searchInput.placeholder = this.root.dataset.dropdownPlaceholder || '';
    }
  };
}

export const init = () => {
  const dropdowns = Array.from(document.querySelectorAll<HTMLElement>('[data-dropdown]'));
  if (!window.UI.dropdown) window.UI.dropdown = { init, instances: [] };

  for (const root of dropdowns) {
    if (!window.UI.dropdown.instances.find((x) => x.root === root)) {
      const dropdown = new Dropdown(root);
      window.UI.dropdown.instances.push(dropdown);
    }
  }
};

export default {
  init,
};
