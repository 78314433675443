const COPY_ICON = `
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.33398" y="2.66675" width="8" height="8" stroke="black"/>
    <path d="M2.66602 5.33325V13.3333H10.666" stroke="black"/>
  </svg>
`;

export class InfoStrip {
  root: HTMLElement;

  constructor(root: HTMLElement) {
    this.root = root;
  }

  init = () => {
    const value = this.root.querySelector<HTMLElement>('.value');
    if (!value) return;

    // read the original value and store in an attribute
    this.root.dataset.value = value.innerText.trim();

    // inject an inline copy button onto this info-strip
    if ('quickCopy' in this.root.dataset) {
      const button = document.createElement('button');
      button.className = 'info-strip-copy';
      button.innerHTML = COPY_ICON;
      button.title = 'Copy Text';

      button.onclick = () => {
        const blob = new Blob([this.root.dataset.value], { type: 'text/plain' });

        const handleCopySuccess = () => {
          value.classList.add('copied');
          setTimeout(() => {
            value.classList.remove('copied');
          }, 500);
        };

        const handleCopyFailure = (error) => {
          console.error('Failed to copy text: ', error);
          alert('Failed to copy text to clipboard.');
        };

        const tryClipboardAPI = () => {
          return navigator.clipboard
            .write([new ClipboardItem({ 'text/plain': blob })])
            .then(handleCopySuccess)
            .catch((error) => {
              console.warn('Clipboard API failed, falling back to older API', error);
              return tryBlobTextAPI();
            });
        };

        const tryBlobTextAPI = () => {
          return blob
            .text()
            .then((text) => navigator.clipboard.writeText(text))
            .then(handleCopySuccess)
            .catch(handleCopyFailure);
        };

        try {
          tryClipboardAPI();
        } catch (error) {
          console.warn('Clipboard API not supported, falling back to older API', error);
          tryBlobTextAPI();
        }
      };

      value.appendChild(button);
    }
  };
}

export const init = () => {
  document.querySelectorAll<HTMLElement>('[data-info-strip]').forEach((root) => {
    const infoStrip = new InfoStrip(root);
    infoStrip.init();
  });
};

export default {
  init,
};
