import regionsHelper from '../shared/regions-helper.js';
import renderHelpers from '../shared/render-helpers';

const ABSOLUTE_PERCENT_CHANGE_METRICS = ['pd_conversion_rate'];

function parseNetPPM(item) {
  if (item.net_ppm !== null && item.net_ppm !== undefined) {
    item.net_ppm = parseFloat(item.net_ppm);
  }
}

function parseConversionRate(item) {
  if (item.pd_conversion_rate !== null && item.pd_conversion_rate !== undefined) {
    item.pd_conversion_rate = parseFloat(item.pd_conversion_rate);
  }
}

function parseSalesInventoryData(item) {
  parseNetPPM(item);
  parseConversionRate(item);
}

class SalesInventoryGraph {
  constructor(rootSelector) {
    this.helpers = renderHelpers;
    this.currencyNamePlural = regionsHelper.getCurrencyNamePlural();
    this.colors = TORO.shared.graphColors;
    this.$root = $(rootSelector);
    this.id = this.$root.attr('id');
    this.salesInventoryData = JSON.parse(this.$root.attr('data-sales-inventory'));

    // sort the data
    this.salesInventoryData.monthlies.sort(function (a, b) {
      if (a.beginning_on < b.beginning_on) {
        return -1;
      } else {
        return 1;
      }
    });

    this.salesInventoryData.weeklies.sort(function (a, b) {
      if (a.beginning_on < b.beginning_on) {
        return -1;
      } else {
        return 1;
      }
    });

    // data formatting...
    this.currentYear = moment().year();
    this.lastYear = moment().subtract(1, 'year').year();

    this.monthlyAxisCategories = [];
    for (var i = 0; i < 12; i++) {
      this.monthlyAxisCategories.push(moment().startOf('year').add(i, 'months').format('MMM'));
    }
    this.weeklyAxisCategories = [];
    for (var i = 0; i < moment().weeksInYear(); i++) {
      var date = moment()
        .week(i + 1)
        .startOf('week')
        .format('[Week] w (YYYY-MM-DD)');
      // var date = moment().week(i + 1).startOf('week').format('YYYY-MM-DD');
      this.weeklyAxisCategories.push(date);
    }

    // data formatting...
    this.siData = { month: {}, week: {} };

    // format Net PPM
    _.each(this.salesInventoryData.monthlies, parseSalesInventoryData);
    _.each(this.salesInventoryData.weeklies, parseSalesInventoryData);

    // Month
    this.siData['month']['shipped_cogs_cents'] = this.getPeriodData('shipped_cogs_cents', 'month');
    this.siData['month']['shipped_units'] = this.getPeriodData('shipped_units', 'month');
    this.siData['month']['sellable_on_hand_inventory_cents'] = this.getPeriodData(
      'sellable_on_hand_inventory_cents',
      'month',
    );
    this.siData['month']['sellable_on_hand_units'] = this.getPeriodData('sellable_on_hand_units', 'month');

    this.siData['month']['retail_price_cents'] = this.getPeriodData('retail_price_cents', 'month');
    this.siData['month']['unit_cost_cents'] = this.getPeriodData('unit_cost_cents', 'month');
    this.siData['month']['pd_shipped_revenue_cents'] = this.getPeriodData('pd_shipped_revenue_cents', 'month');
    this.siData['month']['on_hand_revenue_cents'] = this.getPeriodData('on_hand_revenue_cents', 'month');
    this.siData['month']['open_purchase_order_quantity'] = this.getPeriodData('open_purchase_order_quantity', 'month');

    this.siData['month']['glance_views'] = this.getPeriodData('glance_views', 'month');
    this.siData['month']['pd_conversion_rate'] = this.getPeriodData('pd_conversion_rate', 'month');
    this.siData['month']['net_ppm'] = this.getPeriodData('net_ppm', 'month');

    // Week
    this.siData['week']['shipped_cogs_cents'] = this.getPeriodData('shipped_cogs_cents', 'week');
    this.siData['week']['shipped_units'] = this.getPeriodData('shipped_units', 'week');
    this.siData['week']['sellable_on_hand_inventory_cents'] = this.getPeriodData(
      'sellable_on_hand_inventory_cents',
      'week',
    );
    this.siData['week']['sellable_on_hand_units'] = this.getPeriodData('sellable_on_hand_units', 'week');

    this.siData['week']['retail_price_cents'] = this.getPeriodData('retail_price_cents', 'week');
    this.siData['week']['unit_cost_cents'] = this.getPeriodData('unit_cost_cents', 'week');
    this.siData['week']['pd_shipped_revenue_cents'] = this.getPeriodData('pd_shipped_revenue_cents', 'week');
    this.siData['week']['on_hand_revenue_cents'] = this.getPeriodData('on_hand_revenue_cents', 'week');
    this.siData['week']['open_purchase_order_quantity'] = this.getPeriodData('open_purchase_order_quantity', 'week');

    this.siData['week']['glance_views'] = this.getPeriodData('glance_views', 'week');
    this.siData['week']['pd_conversion_rate'] = this.getPeriodData('pd_conversion_rate', 'week');
    this.siData['week']['net_ppm'] = this.getPeriodData('net_ppm', 'week');

    // 'month' or 'week'
    this.timeView = 'week';

    this.metricDisplayMap = {
      shipped_cogs_cents: 'Shipped COGS',
      shipped_units: 'Shipped Units',
      sellable_on_hand_inventory_cents: 'On Hand Cost',
      sellable_on_hand_units: 'On Hand Units',
      retail_price_cents: 'Retail Price',
      unit_cost_cents: 'Unit Cost',
      pd_shipped_revenue_cents: 'Shipped Revenue',
      on_hand_revenue_cents: 'On Hand Revenue',
      open_purchase_order_quantity: 'Open PO Qty',
      glance_views: 'Glance Views',
      pd_conversion_rate: 'Conversion Rate',
      net_ppm: 'Net PPM',
    };

    this.viewMetrics = ['shipped_cogs_cents'];

    var self = this;

    $('.si-card[data-metric-type="shipped_cogs_cents"]').addClass('selected-metric');

    // Click on the card and basically add a series to the graph
    $('.si-card').on('click', function () {
      var metric = $(this).attr('data-metric-type');
      if (self.viewMetrics.indexOf(metric) === -1) {
        self.viewMetrics.push(metric);
        $(this).addClass('selected-metric');
        self.draw(self.viewMetrics, self.timeView);
      } else {
        self.viewMetrics.splice(self.viewMetrics.indexOf(metric), 1);
        $(this).removeClass('selected-metric');
        self.draw(self.viewMetrics, self.timeView);
      }
    });

    // Change the period and update data accordingly
    $('#period-toggle .btn-period').on('click', function () {
      $('#period-toggle .active').removeClass('active');
      $(this).addClass('active');
      var view = $(this).attr('data-view');
      self.timeView = view;
      self.draw(self.viewMetrics, self.timeView);
      self.updateTable(self.timeView);
    });
  }

  // Update a single card
  updateCardMetricData(metric, unit_str, period) {
    var metrics = this.siData[period][metric][this.lastYear].concat(this.siData[period][metric][this.currentYear]);
    var lastIndex = metrics.length - 1;
    while (lastIndex > 0 && metrics[lastIndex] === null) {
      lastIndex--;
    }
    var latestMetric = lastIndex >= 0 ? metrics[lastIndex] : 0;
    var lastMonthMetric = lastIndex > 0 && metrics[lastIndex - 1] ? metrics[lastIndex - 1] : 0;
    var latestMetricDisplay = latestMetric;

    // Determine if is a dollars vs units, '$' means the metric should be rendered
    // as a dollar.
    if (unit_str === '$') {
      latestMetricDisplay = this.helpers.renderDollar(latestMetric);
    } else if (unit_str === '%') {
      latestMetricDisplay = this.helpers.renderPercent(latestMetric);
    }
    const $card = $(`.si-card[data-metric-type="${metric}"]`);
    $card.find('.card-metric').html(latestMetricDisplay);

    // Remove any previous indicator classes
    // Otherwise, a bug happens when switching from monthly to weekly
    // where the old state is maintained
    $card.find('.card-metric-percent').html('');
    $card.find('.card-metric-percent').removeClass('good');
    $card.find('.card-metric-percent').removeClass('bad');

    var metric_text = '';
    if (lastMonthMetric !== 0) {
      const metricDiff = ABSOLUTE_PERCENT_CHANGE_METRICS.includes(metric)
        ? (latestMetric - lastMonthMetric) * 100
        : ((latestMetric - lastMonthMetric) / lastMonthMetric) * 100;
      metric_text = ' since last ' + period;

      if (metricDiff > 0) {
        $card.find('.card-metric-percent').addClass('good');
      } else {
        $card.find('.card-metric-percent').addClass('bad');
      }

      $card.find('.card-metric-percent').html((metricDiff > 0 ? '+' : '') + Math.round(metricDiff) + '%');
      $card.find('.card-metric-text').html(metric_text);
    } else {
      metric_text = 'No change since last ' + period;
      $card.find('.card-metric-text').html(metric_text);
    }
  }

  // Update the data in the cards (between weekly and monthly).
  updateCardData() {
    this.updateCardMetricData('shipped_cogs_cents', '$', this.timeView);
    this.updateCardMetricData('shipped_units', '', this.timeView);
    this.updateCardMetricData('sellable_on_hand_inventory_cents', '$', this.timeView);
    this.updateCardMetricData('sellable_on_hand_units', '', this.timeView);

    this.updateCardMetricData('retail_price_cents', '$', this.timeView);
    this.updateCardMetricData('unit_cost_cents', '$', this.timeView);
    this.updateCardMetricData('pd_shipped_revenue_cents', '$', this.timeView);
    this.updateCardMetricData('on_hand_revenue_cents', '$', this.timeView);
    this.updateCardMetricData('open_purchase_order_quantity', '', this.timeView);

    this.updateCardMetricData('glance_views', '', this.timeView);
    this.updateCardMetricData('pd_conversion_rate', '%', this.timeView);
    this.updateCardMetricData('net_ppm', '%', this.timeView);
  }

  // format data into the form we want for the graph. Used in contructor
  getPeriodData(dataType, period) {
    var periodData = {};

    periodData[this.currentYear] = [];
    periodData[this.lastYear] = [];

    var length = 12;
    if (period === 'week') {
      length = moment().weeksInYear();
    }

    for (var i = 0; i < length; i++) {
      periodData[this.currentYear].push(null);
      periodData[this.lastYear].push(null);
    }

    var siData = null;
    if (period === 'month') {
      siData = this.salesInventoryData.monthlies;
    } else {
      siData = this.salesInventoryData.weeklies;
    }

    for (var i = 0; i < siData.length; i++) {
      var beginning_on = moment(siData[i].beginning_on);
      var itemIndex = 0;

      // The period Year can be different from the beginning_on.year() for weeks.
      // e.g. Dec 31, 2017 is year 2017, but the period year should be 2018, since
      // in the US calendar it is considered week 1 of 2018.
      var periodYear = period === 'month' ? beginning_on.year() : beginning_on.weekYear();

      if (period === 'month') {
        itemIndex = +beginning_on.format('M') - 1;
      } else {
        itemIndex = +beginning_on.week() - 1;
      }
      // ignore data if not current or previous year
      if (periodData[periodYear]) {
        periodData[periodYear][itemIndex] = siData[i][dataType];
      }
    }

    return periodData;
  }

  // Get the array of graph colors.
  getColors() {
    return [
      '#667279',
      '#D2D5D8',
      '#00ADA7',
      '#C5F4CC',
      '#C6AD00',
      '#FFF573',
      '#0CAD2F',
      '#B7E7C2',
      '#2656FF',
      '#BFCDFF',
      '#F7212E',
      '#FDBEC1',
    ];
  }

  // Updates datatable. Called when the monthly/ weekly toggle is clicked.
  updateTable(timeView) {
    var data;
    if (timeView === 'month') {
      data = this.salesInventoryData.monthlies;
    } else {
      data = this.salesInventoryData.weeklies;
    }
    var dataTable = this.$table.dataTable().api();
    dataTable.clear();
    dataTable.rows.add(data);
    dataTable.draw();
  }

  updateYAxis(targets) {
    // hide all axis
    _.each(this.graph.yAxis, (axis) => {
      axis.update({ visible: false }, false);
    });

    // show axis that correspond to series that are visible
    _.each(targets, (seriesName) => {
      if (_.includes(['retail_price_cents', 'unit_cost_cents'], seriesName)) {
        this.graph.get('dollars2').update({ visible: true }, false);
      } else if (
        _.includes(
          ['shipped_units', 'sellable_on_hand_units', 'open_purchase_order_quantity', 'glance_views'],
          seriesName,
        )
      ) {
        this.graph.get('units').update({ visible: true }, false);
      } else if (_.includes(['net_ppm', 'pd_conversion_rate'], seriesName)) {
        this.graph.get('percentage').update({ visible: true }, false);
      } else {
        this.graph.get('dollars').update({ visible: true }, false);
      }
    });
  }

  // draws the graph called when a card is clicked, period is toggled, and on
  // init.
  draw(metrics, period) {
    var self = this;
    this.updateCardData();

    var xAxisCategories = this.monthlyAxisCategories;
    if (period === 'week') {
      xAxisCategories = this.weeklyAxisCategories;
    }

    var metricSeries = [];
    for (var i = 0; i < metrics.length; i++) {
      let graphType, graphYAxis;
      if (_.includes(['retail_price_cents', 'unit_cost_cents'], metrics[i])) {
        graphType = 'line';
        graphYAxis = 2;
      } else if (
        _.includes(
          ['shipped_units', 'sellable_on_hand_units', 'open_purchase_order_quantity', 'glance_views'],
          metrics[i],
        )
      ) {
        graphType = 'column';
        graphYAxis = 1;
      } else if (_.includes(['net_ppm', 'pd_conversion_rate'], metrics[i])) {
        graphType = 'column';
        graphYAxis = 2;
      } else {
        graphType = 'line';
        graphYAxis = 0;
      }

      var sCurrentYear = {
        name: this.metricDisplayMap[metrics[i]] + ' (' + this.currentYear + ')',
        data: this.siData[period][metrics[i]][this.currentYear],
        type: graphType,
        yAxis: graphYAxis,
        zIndex: 2,
        crisp: false,
        tooltip: {
          valueSuffix: '',
        },
      };
      var sLastYear = {
        name: this.metricDisplayMap[metrics[i]] + ' (' + this.lastYear + ')',
        data: this.siData[period][metrics[i]][this.lastYear],
        type: graphType,
        yAxis: graphYAxis,
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 2,
        },
        zIndex: 1,
        crisp: false,
        tooltip: {
          valueSuffix: '',
        },
      };

      metricSeries.push(sCurrentYear);
      metricSeries.push(sLastYear);
    }

    // line & column chart
    this.graph = Highcharts.chart('product-sales-inventory-graph', {
      colors: this.getColors(),
      chart: {
        zoomType: 'x',
      },
      title: {
        text: 'Product Sales and Inventory',
      },
      xAxis: [
        {
          categories: xAxisCategories,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          id: 'dollars',
          title: {
            text: this.currencyNamePlural,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          labels: {
            formatter: function () {
              return self.helpers.renderDollar(this.value);
            },
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          visible: false,
        },
        {
          // Secondary yAxis
          id: 'units',
          title: {
            text: 'Units',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            formatter: function () {
              return self.helpers.renderNumber(this.value);
            },
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
          visible: false,
        },
        {
          id: 'dollars2',
          title: {
            text: `${this.currencyNamePlural} (Unit price/cost)`,
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            formatter: function () {
              return self.helpers.renderDollar(this.value);
            },
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
          visible: false,
        },
        {
          id: 'percentage',
          title: {
            text: 'Percentage',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            formatter: function () {
              return self.helpers.renderPercent(this.value);
            },
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
          visible: false,
        },
      ],
      plotOptions: {},
      tooltip: {
        shared: true,
        formatter: function () {
          var s = '';
          s += '<b>' + this.x + '</b>';
          _.each(this.points, function (point) {
            s += '<br/>' + point.series.name + ': ';

            // Show plain number for the following metrics
            // - open_purchase_order_quantity
            // - sellable_on_hand_units
            // - glance_views
            //
            // Show percentage number for the following metrics
            // - net_ppm
            // - pd_conversion_rate
            //
            // Show dollar otherwise
            if (
              _.includes(point.series.name, 'Units') ||
              _.includes(point.series.name, 'Qty') ||
              _.includes(point.series.name, 'Glance Views')
            ) {
              s += '<b>' + self.helpers.renderNumber(point.y) + '</b>';
            } else if (_.includes(point.series.name, 'Net PPM') || _.includes(point.series.name, 'Conversion Rate')) {
              s += '<b>' + self.helpers.renderPercent(point.y) + '</b>';
            } else {
              s += '<b>' + self.helpers.renderDollar(point.y) + '</b>';
            }
          });

          return s;
        },
      },
      series: metricSeries,
    });

    this.updateYAxis(metrics);
    this.graph.redraw();
  }

  // Initialize the table for the first time.
  initSITable() {
    const self = this;

    this.$table = $('#sale-inventory-table');

    var siData;
    if (this.timeView === 'month') {
      siData = this.salesInventoryData.monthlies;
    } else {
      siData = this.salesInventoryData.weeklies;
    }

    const buttons = [];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Sales And Inventory',
      });
    }

    const dataTable = this.$table.DataTable({
      lengthMenu: [5, 10, 25, 50],
      paging: true,
      pageLength: 5,
      dom: TORO.shared.dataTableDefaults.dom,
      language: {
        search: '',
        searchPlaceholder: 'Search...',
      },
      buttons: buttons,
      order: [[0, 'desc']],
      data: siData,
      columns: [
        {
          data: 'beginning_on',
        },
        {
          data: 'period',
          render: this.helpers.renderTitle,
        },
        {
          data: 'shipped_cogs_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'shipped_units',
        },
        {
          data: 'sellable_on_hand_inventory_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'sellable_on_hand_units',
        },
        {
          data: 'retail_price_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'unit_cost_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'pd_shipped_revenue_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'on_hand_revenue_cents',
          render: this.helpers.renderDollar,
        },
        {
          data: 'open_purchase_order_quantity',
        },
        {
          data: 'glance_views',
        },
        {
          data: 'pd_conversion_rate',
          render: function (data, type, row) {
            if (data) {
              if (parseFloat(data) >= 0) {
                return self.helpers.renderPercent(data);
              }
            } else {
              return '-';
            }
          },
        },
        {
          data: 'net_ppm',
          render: function (data, type, row) {
            if (data) {
              if (parseFloat(data) >= 0) {
                return '<span class="metric-change good">+' + self.helpers.renderPercent(data) + '</span>';
              } else {
                return '<span class="metric-change bad">' + self.helpers.renderPercent(data) + '</span>';
              }
            } else {
              return '-';
            }
          },
        },
      ],
    });
  }

  init() {
    this.draw(this.viewMetrics, this.timeView);
    this.initSITable();
  }
}

export default SalesInventoryGraph;
