import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class AmazonReviews {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.csvUrl = this.$table.attr('data-csv-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.beginningOn = this.$table.attr('data-beginning-on');
    this.$reviewsCount = $('.js-reviews-count');

    this.helpers = renderHelpers;
  }

  initDatePicker() {
    this.datePicker = $('#demo-reviews')
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          // this.dataTable.ajax.reload();
          this.dataTable.ajax.url(this.ajaxUrl).load();
        },
      )
      .data('daterangepicker');
  }

  updateRatingFilter() {
    const targets = [];
    this.ratingFilter.find('option:selected').each(function () {
      targets.push($(this).val());
    });
    const regex = targets.map(TORO.shared.escapeRegExp).join('|');
    this.dataTable.column(this.definition.columnIndex('rating')).search(regex, false, false).draw();
  }

  initRatingFilter() {
    this.ratingFilter = $('#amazon-reviews_rating-filter');
    this.ratingFilter.on('change', () => this.updateRatingFilter());
  }

  defineTable() {
    this.definition = new DataTableDefinition({
      defaultSortByColumns: ['review_created_on', 'asin'],
      defaultSortByDirections: ['desc', 'asc'],
      columns: [
        {
          name: 'review_created_on',
          data: 'review_created_on',
        },
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            var link = this.helpers.asinLink(this.asinUrl, data, 'reviews');
            return '<a class="link" href="' + link + '">' + data + '</a>';
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
        },
        {
          name: 'upc',
          data: 'upc',
          visible: false,
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'replenishment_code',
          data: 'replenishment_code',
          visible: false,
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          visible: false,
          render: this.helpers.renderTagUnescaped,
        },
        {
          name: 'rating',
          data: 'rating',
        },
        {
          name: 'author_name',
          data: 'author_name',
          render: this.helpers.renderReviewAuthor,
        },
        {
          name: 'author_link',
          data: 'author_link',
          visible: false,
        },
        {
          name: 'title',
          data: 'title',
          render: (data, type, row) => {
            if (type === 'export') {
              return data;
            } else {
              return this.helpers.renderReviewTitle(data, type, row);
            }
          },
        },
        {
          name: 'content',
          data: 'content',
          render: (data, type, row) => {
            if (type === 'export') {
              return data;
            } else {
              return `
                <div data-show-more data-sm-height="150">
                  ${data}
                </div>
              `;
            }
          },
        },
        {
          name: 'review_link',
          data: 'review_link',
          visible: false,
        },
        {
          name: 'verified_purchase',
          data: 'verified_purchase',
          visible: false,
        },
      ],
    });
  }

  initTable() {
    const buttons = [{
      extend: 'ilColvis',
      columns: this.definition.columnIndexes([
        'review_created_on',
        'asin',
        'asin_name',
        'upc',
        'model_number',
        'replenishment_code',
        'tag_name',
        'rating',
        'author_name',
        'author_link',
        'title',
        'content',
        'review_link',
        'verified_purchase',
      ]),
    }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsvServer',
        url: this.csvUrl,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        type: 'POST',
        url: this.ajaxUrl,
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        dataSrc: 'data',
        // dataSrc: (json) => { // Good for debugging what was returned
        //   return json;
        // },
      },
      serverSide: true,
      processing: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('reviews'),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.$reviewsCount.html(json.recordsTotal || '0');
    });
  }

  init() {
    this.initDatePicker();
    this.initRatingFilter();
    this.defineTable();
    this.initTable();

    this.updateRatingFilter();
  }
}

export default AmazonReviews;
