import Fuse, { type IFuseOptions } from 'fuse.js';
import { debounce } from 'helpers';

export interface SearchableItem {
  text: string;
  element: HTMLElement;
}

export class UiSearchable extends HTMLElement {
  input: HTMLInputElement;
  items: SearchableItem[] = [];

  searchNoResult?: HTMLElement;
  searchTruncation?: HTMLElement;
  searchTruncationText?: HTMLElement;
  searchTruncationShowAll?: HTMLButtonElement;
  fuse: Fuse<SearchableItem>;

  maxDisplay = 100;

  constructor() {
    super();
    this.input = this.querySelector('input[data-search]');
    if (!this.input) return;

    this.input.addEventListener('input', this.update);
    this.searchNoResult = this.querySelector('[data-search-no-results]');
    this.searchTruncation = this.querySelector('[data-search-truncation]');
    this.searchTruncationText = this.querySelector('[data-search-truncation-text]');
    this.searchTruncationShowAll = this.querySelector('[data-search-truncation-show-all]');

    if (this.searchTruncationShowAll) this.searchTruncationShowAll.addEventListener('click', this.showAll);

    this.items = Array.from(this.querySelectorAll<HTMLElement>('ui-searchable-items ui-searchable-item')).map(el => {
      return {
        text: el.innerText,
        element: el,
      };
    });

    const fuseOptions: IFuseOptions<SearchableItem> = {
      keys: ['text'],
      useExtendedSearch: true,
      ignoreLocation: true,
    };

    let threshold = parseFloat(this.getAttribute('data-search-threshold'));
    fuseOptions.threshold = isNaN(threshold) ? 0.1 : threshold;

    this.fuse = new Fuse(this.items, fuseOptions);

    this.update();
  }

  update = () => {
    this.setAttribute('loading', '');
    this.search();
  }
  0
  setVisibility = (el: HTMLElement, show: boolean) => {
    if (show && el.style.display === 'none') el.style.display = null;
    else if (!show && el.style.display !== 'none') el.style.display = 'none';
  }

  search = debounce(() => {
    const query = this.input.value || '';
    const results = query ? this.fuse.search(query).map(r => r.item) : this.items;

    // hide everything
    this.items.forEach(item => this.setVisibility(item.element, false));

    // show only the first N elements
    for (const [i, item] of results.entries()) {
      item.element.style.display = i < this.maxDisplay ? null : 'none';
    }

    // display truncation text
    if (this.searchTruncation) {
      if (results.length > this.maxDisplay) {
        const overflow = results.length - this.maxDisplay;
        this.searchTruncation.classList.add('visible');
        this.searchTruncationText.innerText = `${overflow} ${overflow > 1 ? 'items' : 'item'} truncated.`;
      } else {
        this.searchTruncation.classList.remove('visible');
        this.searchTruncationText.innerText = '';
      }
    }

    // no results text
    if (this.searchNoResult) {
      if (!query || results.length > 0) this.searchNoResult.classList.remove('visible');
      else this.searchNoResult.classList.add('visible');
    }

    this.removeAttribute('loading');
  }, 250);

  showAll = () => {
    this.maxDisplay = 10000;
    this.searchTruncationShowAll.remove();
    this.update();
  }
}

export class UiSearchableItems extends HTMLElement { }

export class UiSearchableItem extends HTMLElement { }
