import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class AmazonMapViolations {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.asinUrl = this.$table.attr('data-asin-url');
    this.filename = this.$table.attr('data-filename');
    this.$mapViolationsCount = $('.js-map-violations-count');
    this.helpers = renderHelpers;
  }

  defineTable() {
    this.tableDefinition = new DataTableDefinition({
      defaultSortByColumns: ['crawled_at'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'crawled_at',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'asin',
          data: 'asin',
          render: (data, type, row) => {
            const link = this.helpers.asinLink(this.asinUrl, data, 'map-violations');
            return '<a class="link" href="' + link + '">' + data + '</a>';
          },
        },
        {
          name: 'asin_name',
          data: 'asin_name',
        },
        {
          name: 'upc',
          data: 'upc',
          visible: false,
        },
        {
          name: 'model_number',
          data: 'model_number',
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          visible: false,
          render: this.helpers.renderTag,
        },
        {
          name: 'min_price_cents',
          data: 'min_price_cents',
          render: (data, type, row, meta) => {
            return this.helpers.renderDollar(data);
          },
        },
        {
          name: 'buy_box_price_cents',
          data: 'buy_box_price_cents',
          render: (data, type, row, meta) => {
            return this.helpers.renderDollar(data, false);
          },
        },
        {
          name: 'list_price_cents',
          data: 'list_price_cents',
          render: (data, type, row, meta) => {
            return this.helpers.renderDollar(data);
          },
        },
        {
          name: 'seller_text',
          data: 'seller_text',
          render: (data, type, row, meta) => {
            return data || '-';
          },
        },
        {
          name: 'seller_aid',
          data: 'seller_aid',
          render: (data, type, row, meta) => {
            if (data) {
              return `<a href="https://www.amazon.com/sp?seller=${data}" target="_blank">${data}</a>`;
            } else {
              return '-';
            }
          },
          visible: false,
        },
      ],
    });
  }

  initTable() {
    const buttons = [{ extend: 'ilColvis' }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: this.filename,
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 10,
      ajax: {
        url: this.ajaxUrl,
        dataSrc: '',
      },
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('products'),
      buttons: buttons,
      order: this.tableDefinition.order(),
      columns: this.tableDefinition.columns,
    });

    this.dataTable.on('xhr.dt', (e, settings, json) => {
      this.$mapViolationsCount.html(json.length || '0');
    });
  }

  applyQuerySearch() {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('map_violations_search');
    if (search) this.dataTable.search(search);
  }

  init() {
    this.defineTable();
    this.initTable();

    this.applyQuerySearch();
  }
}

export default AmazonMapViolations;
