import { onPageLoad } from './helpers';
import headerPrimary from './components/header-primary';
import headerSecondary from './components/header-secondary';
import sidebar from './components/sidebar';
import accordion from './components/accordion';
import alert from './components/alert';
import dropdown from './components/dropdown';
import dialog from './components/dialog';
import infoStrip from './components/info-strip';
import showMore from './components/show-more';
import tiptap from './components/tiptap';
import { UiTabs, UiTab, UiTabContent } from './components/tabs';
import { UiSearchable, UiSearchableItems, UiSearchableItem } from './components/searchable';
import { UiToggleSwitch } from './components/toggle-switch';
import { UiFileDropper } from './components/file-dropper';

import aplusCompare from './components/aplus-compare';
import aplusModule from './components/aplus-module';

import ProductForecastsLine from './components/charts/product-forecasts-line';
import ProductPurchaseOrdersTimeline from './components/charts/product-purchase-orders-timeline';
import ProductPurchaseOrdersFunnel from './components/charts/product-purchase-orders-funnel';
import ProductBestSellerRanksChart from './components/charts/product-best-seller-ranks-chart';

// Nav
import AccountSelector from './src/nav/account-selector';

// Analysis
import AnalysisGroups from './src/analysis/groups';
import AnalysisCompare from './src/analysis/compare';

// Products
import ProductsVendor from './src/products/vendor';
import ProductsSeller from './src/products/seller';
import ProductsMonitored from './src/products/monitored';
import ProductSalesInventory from './src/products/sales-inventory';
import ProductReviews from './src/products/product-reviews';
import ProductBestSellerRanks from './src/products/product-best-seller-ranks';
import VcChangeTable from './src/products/vc-change-table';
import ProductChange from './src/products/product-content-changes';
import ProductBuyBoxes from './src/products/product-buy-boxes.js';
import ProductPurchaseOrders from './src/products/product-purchase-orders.js';
import ProductForecasts from './src/products/product-forecasts.js';

//Reports
import MonthlyReportsReceivedGraph from './src/arap_monthly_reports/monthly-report-received-graph';
import MonthlyReportsReceivedTable from './src/arap_monthly_reports/monthly-report-received-table';
import MonthlyReportsShippedGraph from './src/arap_monthly_reports/monthly-report-shipped-graph';
import MonthlyReportsShippedTable from './src/arap_monthly_reports/monthly-report-shipped-table';
import WeeklyReportsShippedGraph from './src/arap_weekly_reports/weekly-report-shipped-graph';
import WeeklyReportsShippedTable from './src/arap_weekly_reports/weekly-report-shipped-table';
import WeeklyReportsReceivedGraph from './src/arap_weekly_reports/weekly-report-received-graph';
import WeeklyReportsReceivedTable from './src/arap_weekly_reports/weekly-report-received-table';

// User Settings
import NotificationSettings from './src/users/notifications-settings';

// Vendor Central
import Chargebacks from './src/vendor_central/chargebacks';
import ProductChanges from './src/vendor_central/product-changes';
import PurchaseOrders from './src/vendor_central/purchase-orders';

// Amazon.com
import AmazonContentChanges from './src/amazon/content-changes';
import AmazonNewOffers from './src/amazon/new-offers';
import AmazonBuyBoxes from './src/amazon/buy-boxes';
import AmazonReviews from './src/amazon/reviews';
import AmazonMapViolations from './src/amazon/map-violations';

// AMS
import AttachProfile from './src/ams/attach-profile';
import CompareMetricsPage from './src/ams/compare-metrics-page';

// Export
import Export from './src/export/export';

onPageLoad(async () => {
  // IMPORT GENERIC COMPONENTS & NEW SCRIPTS ---------------------------------------------------- //

  window.UI = {};
  headerPrimary.init();
  headerSecondary.init();
  sidebar.init();
  accordion.init();
  alert.init();
  dropdown.init();
  dialog.init();
  infoStrip.init();
  showMore.init();
  tiptap.init();

  aplusCompare.init();
  aplusModule.init();

  customElements.define('ui-tabs', UiTabs);
  customElements.define('ui-tab', UiTab);
  customElements.define('ui-tab-content', UiTabContent);
  customElements.define('ui-searchable', UiSearchable);
  customElements.define('ui-searchable-items', UiSearchableItems);
  customElements.define('ui-searchable-item', UiSearchableItem);
  customElements.define('ui-toggle-switch', UiToggleSwitch);
  customElements.define('ui-file-dropper', UiFileDropper);

  customElements.define('product-forecasts-line', ProductForecastsLine);
  customElements.define('product-purchase-orders-timeline', ProductPurchaseOrdersTimeline);
  customElements.define('product-purchase-orders-funnel', ProductPurchaseOrdersFunnel);
  customElements.define('product-best-seller-ranks-chart', ProductBestSellerRanksChart);

  // IMPORT LEGACY SCRIPTS ---------------------------------------------------------------------- //

  const initIfExist = function (klass, selector) {
    if (document.querySelector(selector)) {
      new klass(selector).init();
    }
  };

  // NAV
  initIfExist(AccountSelector, '#nav__account-selector');

  // ANALYSIS
  initIfExist(AnalysisGroups, '#grouping');
  initIfExist(AnalysisCompare, '#compare');

  // PRODUCTS
  initIfExist(ProductsVendor, '#vendor-products-table');
  initIfExist(ProductsSeller, '#seller-products-table');
  initIfExist(ProductsMonitored, '#monitored-products-table');

  initIfExist(ProductSalesInventory, '#product-sales-inventory-graph');
  initIfExist(ProductBuyBoxes, '#product-buy-box-table');
  initIfExist(ProductReviews, '#product-reviews-table');
  initIfExist(ProductBestSellerRanks, '#product-best-seller-ranks');
  initIfExist(VcChangeTable, '#product-update-table');
  initIfExist(ProductChange, '#content-changes-table');
  initIfExist(ProductPurchaseOrders, '#product-purchase-orders-table');
  initIfExist(ProductForecasts, '#product-forecasts');

  initIfExist(MonthlyReportsReceivedGraph, '#monthly-reports-received-graph');
  initIfExist(MonthlyReportsReceivedTable, '#monthly-reports-received-table');
  initIfExist(MonthlyReportsShippedGraph, '#monthly-reports-shipped-graph');
  initIfExist(MonthlyReportsShippedTable, '#monthly-reports-shipped-table');
  initIfExist(WeeklyReportsShippedGraph, '#weekly-reports-shipped-graph');
  initIfExist(WeeklyReportsShippedTable, '#weekly-reports-shipped-table');
  initIfExist(WeeklyReportsReceivedGraph, '#weekly-reports-received-graph');
  initIfExist(WeeklyReportsReceivedTable, '#weekly-reports-received-table');

  // USER Settings
  initIfExist(NotificationSettings, '#js-user-settings');

  // Vendor Central
  initIfExist(Chargebacks, '#chargebacks');
  initIfExist(ProductChanges, '#product-changes');
  initIfExist(PurchaseOrders, '#purchase-orders');

  // Amazon.com
  initIfExist(AmazonContentChanges, '#amazon-changes-table');
  initIfExist(AmazonNewOffers, '#amazon-offers-table');
  initIfExist(AmazonBuyBoxes, '#amazon-buy-boxes-table');
  initIfExist(AmazonReviews, '#amazon-reviews-table-new');
  initIfExist(AmazonMapViolations, '#amazon-map-violations-table');

  // AMS
  initIfExist(AttachProfile, '.attach-profile-block');
  initIfExist(CompareMetricsPage, '#compare-metrics-page');

  // Export
  initIfExist(Export, '#export-account');
});
