class AmazonBestSellerRanks {
  constructor(rootSelector) {
    this.root = $(rootSelector);
    this.ajaxUrl = this.root.attr('data-ajax-url');
    this.productToken = this.root.attr('data-product-token');
  }

  initDatePicker() {
    this.datePicker = $('#product-best-seller-ranks-date')
      .daterangepicker(
        {
          showWeekNumbers: true,
          startDate: moment().subtract(90, 'days'),
          endDate: moment(),
          opens: 'left',
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
        },
        (start, end, label) => {
          this.fetchData();
        },
      )
      .data('daterangepicker');
  }

  fetchData() {
    const startDate = this.datePicker.startDate.format('YYYY-MM-DD');
    const endDate = this.datePicker.endDate.format('YYYY-MM-DD');
    $.ajax({
      type: 'GET',
      url: this.ajaxUrl,
      data: {
        beginning_on: startDate,
        ending_on: endDate,
        token: this.productToken,
      },
      success: function (data) {
        const chart = document.getElementById('product-best-seller-ranks-chart');
        if (!chart) return;

        chart.startDate = startDate;
        chart.endDate = endDate;
        chart.bestSellerRanks = data['category_ranks'];
      },
    });
  }

  init() {
    this.initDatePicker();
    this.fetchData();
  }
}

export default AmazonBestSellerRanks;
