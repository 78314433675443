import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class Chargebacks {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.asinUrl = this.$root.attr('data-asin-url');
    this.$datePicker = $('#chargebacks__input');
    this.$table = $('#chargebacks__table');
    this.helpers = renderHelpers;

    // Modal
    this.$modal = $('#chargebacks__modal');
    this.$modalTitle = $('#chargebacks__modal-title');
    this.$modalBody = $('#chargebacks__modal-body');
  }

  initDatePicker() {
    this.datePicker = this.$datePicker
      .daterangepicker(
        {
          showWeekNumbers: true,
          ranges: {
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Two Weeks Ago': [
              moment().subtract(2, 'weeks').startOf('week'),
              moment().subtract(2, 'weeks').endOf('week'),
            ],
            'Two Months Ago': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(2, 'months').endOf('month'),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          maxDate: moment(),
        },
        (start, end, label) => {
          this.dataTable.ajax.url(location.pathname + '.json').load();
        },
      )
      .data('daterangepicker');
  }

  initStatusFilter() {
    this.statusFilter = $('#chargebacks_status-filter');

    this.statusFilter.on('change', () => {
      const targets = [];
      this.statusFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.definition.columnIndex('status')).search(`^(${regex})$`, true, false).draw();
    });
  }

  initIssueCategoryFilter() {
    this.issueCategoryFilter = $('#chargebacks_issue-category-filter');

    this.issueCategoryFilter.on('change', () => {
      const targets = [];
      this.issueCategoryFilter.find('option:selected').each(function () {
        targets.push($(this).val());
      });
      const regex = targets.map(TORO.shared.escapeRegExp).join('|');
      this.dataTable.column(this.definition.columnIndex('issue_category')).search(`^(${regex})$`, true, false).draw();
    });
  }

  updateFilterOptions(data) {
    const statuses = _.uniq(data.map(x => x.status));
    const statusOptions = statuses.map(x => {
      return `<option value="${x}" selected>${x}</option>`;
    });
    this.statusFilter.html(statusOptions.join(''));

    const categories = _.uniq(data.map(x => x.issue_category));
    const categoryOptions = categories.map(category => {
      const categoryName = this.helpers.renderIssueType(category);
      return `<option value="${categoryName}" selected>${categoryName}</option>`;
    });
    this.issueCategoryFilter.html(categoryOptions.join(''));
  }

  defineTable() {
    this.definition = new DataTableDefinition({
      defaultSortByColumns: ['creation_date'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'creation_date',
          data: 'creation_date',
        },
        {
          name: 'issue_id',
          data: 'issue_id',
          render: (data, type, row) => {
            return `<a href="#" class="link js-chargeback-details">${data}</a>`;
          },
        },
        {
          name: 'issue_type',
          data: 'issue_type',
          render: this.helpers.renderTitle,
        },
        {
          name: 'issue_category',
          data: 'issue_category',
          render: this.helpers.renderIssueType,
        },
        {
          name: 'status',
          data: 'status',
        },
        {
          name: 'financial_charge_cents',
          data: 'financial_charge_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'dispute_by',
          data: 'details.dispute_by',
          defaultContent: '',
        },
        {
          name: 'asin',
          data: 'details.asin',
          defaultContent: '',
          render: (data, type, row) => {
            if (data) {
              const link = this.helpers.asinLink(this.asinUrl, data);
              return '<a class="link" href="' + link + '">' + data + '</a>';
            } else {
              return '';
            }
          },
        },
        {
          name: 'po',
          data: 'details.purchase_order_#',
          defaultContent: '',
        },
      ],
    });
  }

  initTable() {
    const buttons = [{
      extend: 'ilColvis',
      columns: this.definition.columnIndexes([
        'creation_date',
        'issue_id',
        'issue_type',
        'issue_category',
        'status',
        'financial_charge_cents',
        'dispute_by',
        'asin',
        'po',
      ]),
    }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Chargebacks',
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 25,
      ajax: {
        url: location.pathname + '.json',
        data: (d) => {
          d.beginning_on = this.datePicker.startDate.format('YYYY-MM-DD');
          d.ending_on = this.datePicker.endDate.format('YYYY-MM-DD');
        },
        // dataSrc: '',
        dataSrc: (json) => {
          this.updateFilterOptions(json);
          this.tableData = json;
          return json;
        },
      },
      processing: true,
      deferRender: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('chargebacks'),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
    });
  }

  findChargeback(issueId) {
    return _.find(this.tableData, ['issue_id', issueId]);
  }

  chargebackDetailsHTML(details) {
    const bullets = _.compact(
      _.map(details, (value, key) => {
        let val = value;
        if (value === null) {
          return null;
        }
        if (key === 'financial_charge') {
          val = this.helpers.renderDollar(value.fractional);
        }
        const title = _.capitalize(_.replace(key, /_/g, ' '));

        return `<tr><th class="font-medium">${title}</th><td>${val}</td></tr>`;
      }),
    );

    return `<table class="dataTable">${bullets.join('\n')}</table>`;
  }

  showChargebackDetails(issueId) {
    this.$modalTitle.empty();
    this.$modalBody.empty();

    const chargeback = this.findChargeback(issueId);
    this.$modalTitle.text(chargeback.issue_id);
    this.$modalBody.html(this.chargebackDetailsHTML(chargeback.details));
    this.$modal.modal('show');
  }

  initHandlers() {
    this.$table.on('click', '.js-chargeback-details', (e) => {
      e.preventDefault();
      const issueId = $(e.currentTarget).text();
      this.showChargebackDetails(issueId);
    });
  }

  init() {
    this.initDatePicker();
    this.initStatusFilter();
    this.initIssueCategoryFilter();
    this.defineTable();
    this.initTable();
    this.initHandlers();
  }
}

export default Chargebacks;
