import diff from 'node-htmldiff';

const diffOpts = [
  'aplus-diff', // className
  null, // dataPrefix
  'iframe,object,math,svg,script,video,head,style,aplus-nodiff' // atomicTags
];

export const init = () => {
  /**
   * Simple script for setting active A+ content on aplus comparison modals.
   * See _product_aplus_compare_modal.html.erb for the corresponding template.
   */
  document.body.addEventListener('click', evt => {
    const target = evt.target as HTMLElement;
    if (!target.hasAttribute('data-aplus-compare')) return;

    const dialog = document.querySelector<HTMLElement>('dialog[data-dialog=aplus-compare-modal]');
    const title = document.getElementById('aplus-compare-modal-title');
    if (dialog && title) {
      const style = target.getAttribute('data-aplus-compare-style') || '';
      switch (style) {
        case 'aplus':
          dialog.dataset.aplusCompareStyle = style;
          title.innerText = 'Compare A+ Contents';
          break;

        default:
          dialog.dataset.aplusCompareStyle = 'html';
          title.innerText = 'Compare HTML Contents';
          break;
      }
    }

    const left = document.getElementById('aplus-compare-left');
    const right = document.getElementById('aplus-compare-right');
    const htmlDiff = document.getElementById('aplus-compare-diff');

    const oldValue = target.getAttribute('data-aplus-compare-old') || '';
    const newValue = target.getAttribute('data-aplus-compare-new') || '';
    const htmlDiffOutput = diff(oldValue, newValue, ...diffOpts);

    if (left) left.innerHTML = htmlDiffOutput;
    if (right) right.innerHTML = htmlDiffOutput;
    if (htmlDiff) htmlDiff.innerHTML = htmlDiffOutput;
  });
};

export default {
  init,
};
