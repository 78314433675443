export class Alert {
  root: HTMLElement;
  dismiss: HTMLElement;

  constructor(root: HTMLElement) {
    this.root = root;
    this.dismiss = root.querySelector('.alert-dismiss');
  }

  init = () => {
    this.dismiss.addEventListener('click', this.dismissAlert);
  };

  dismissAlert = () => {
    this.root.parentElement.removeChild(this.root);
  };
}

export const init = () => {
  const alerts = Array.from(document.querySelectorAll<HTMLElement>('[data-alert]'));
  if (!window.UI.alert) window.UI.alert = { init, instances: [] };

  for (const root of alerts) {
    if (!window.UI.alert.instances.find((x) => x.root === root)) {
      const alert = new Alert(root);
      alert.init();
      window.UI.alert.instances.push(alert);
    }
  }
};

export default {
  init,
};
