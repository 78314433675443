class NotificationSettings {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.$allCheckbox = this.$root.find('.js-user-settings__all-checkbox');
    this.$accountsContainer = this.$root.find('.js-user-settings__accounts-container');
  }

  checkAllAccounts() {
    this.$accountsContainer.find('input:checkbox').prop('checked', true);
  }

  handleAllCheck = () => {
    if (this.$allCheckbox.prop('checked')) {
      this.checkAllAccounts();
    }
  };

  handleAccountCheck = () => {
    this.$allCheckbox.prop('checked', false);
  };

  init() {
    if (this.$allCheckbox.prop('checked')) {
      this.checkAllAccounts();
    }
    this.$allCheckbox.click(this.handleAllCheck);
    this.$accountsContainer.on('click', 'input:checkbox', this.handleAccountCheck);
  }
}

export default NotificationSettings;
