import { generateId } from '../helpers';

export class Accordion {
  root: HTMLElement;
  toggles: HTMLElement[];
  content: HTMLElement | null;
  contentObserver: ResizeObserver;
  contentHeight = 'auto';

  id: string;
  group: string | null;
  isOpen: boolean;

  constructor(root: HTMLElement) {
    this.root = root;
    this.content = root.querySelector<HTMLElement>('[data-accordion-content]');
    if (this.content == null) console.warn('[ui:accordion] No content element found.');

    this.id = root.dataset.accordion || generateId('accordion');
    this.group = root.dataset.accordionGroup || null;
    if (root.dataset.accordion !== this.id) root.dataset.accordion = this.id;

    // add all id-irrelevant toggles inside this accordion
    this.toggles = Array.from(root.querySelectorAll<HTMLElement>('[data-accordion-toggle=""]'));

    // add all toggles elsewhere in the document that point specifically to this accordion's id
    document.querySelectorAll<HTMLElement>(`[data-accordion-toggle="${this.id}"]`).forEach((toggle) => {
      this.toggles.push(toggle);
    });

    this.contentObserver = new ResizeObserver(this.updateHeight);

    this.isOpen = root.dataset.accordionOpen == 'true' || false;
    if (this.isOpen) this.content?.classList.add('accordion-open');
  }

  open = () => {
    if (this.root.hasAttribute('data-accordion-disabled')) return;

    this.content?.classList.remove('hidden');
    this.updateHeight();

    this.root.dataset.accordionOpen = 'true';
    this.content?.classList.add('accordion-open');
    this.isOpen = true;
    this.updateHeight();

    // close all other accordions from the same group
    if (this.group && window.UI.accordion) {
      const targets = window.UI.accordion.instances.filter((acc) => acc.group === this.group && acc.id !== this.id);
      for (const accordion of targets) accordion.close();
    }
  };

  close = () => {
    if (this.root.hasAttribute('data-accordion-disabled')) return;

    delete this.root.dataset.accordionOpen;
    this.content?.classList.remove('accordion-open');
    this.isOpen = false;
    if (this.content) this.content.style.maxHeight = this.content.scrollHeight + 'px';
    this.updateHeight();
  };

  toggle = (evt?: MouseEvent) => {
    if (this.root.dataset.accordionOpen === 'true') this.close();
    else this.open();

    if (evt) evt.preventDefault();
  };

  init = () => {
    for (const t of this.toggles) {
      t.addEventListener('click', this.toggle);
    }

    if (this.content) this.contentObserver.observe(this.content);
    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
  };

  destroy = () => {
    for (const t of this.toggles) {
      t.removeEventListener('click', this.toggle);
    }

    if (this.content) this.contentObserver.disconnect();

    window.removeEventListener('resize', this.updateHeight);
  };

  updateHeight = () => {
    if (!this.content) return;

    requestAnimationFrame(() => {
      // Don't trigger an update if the root accordion is not visible.
      /**
       * Note: we need to make an additional existence check here for the checkVisibility function
       * to prevent errors on older Safari browsers.
       * See: https://insightleap.sentry.io/share/issue/3e264f9ec0b54593a5713ccb42436480/
       */
      if (!this.root || (this.root.checkVisibility && this.root.checkVisibility() === false)) return;

      this.contentHeight = this.content.scrollHeight + 'px';
      this.content.dataset.height = this.contentHeight;

      if (this.isOpen && this.content.clientHeight === this.content.scrollHeight) {
        // when the accordion is opening and it has reached its target height, we set the max-height to auto
        this.content.style.maxHeight = 'none';
      } else {
        // for all other cases, we wait for the transition to naturally happen
        this.content.style.maxHeight = this.isOpen ? this.contentHeight : '0px';
        if (!this.isOpen && this.content.clientHeight === 0) this.content.classList.add('hidden');
      }
    });
  };
}

export const init = () => {
  const accordions = Array.from(document.querySelectorAll<HTMLElement>('[data-accordion]'));
  if (!window.UI.accordion) window.UI.accordion = { init, instances: [] };

  for (const root of accordions) {
    if (!window.UI.accordion.instances.find((x) => x.root === root)) {
      const accordion = new Accordion(root);
      accordion.init();
      window.UI.accordion.instances.push(accordion);
    }
  }
};

export default {
  init,
};
