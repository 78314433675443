import * as Plot from '@observablehq/plot';
import * as htl from 'htl';

export default class ProductPurchaseOrdersFunnel extends HTMLElement {
  static observedAttributes = ['mode'];

  _purchaseOrders = [];

  set purchaseOrders(pos) {
    this._purchaseOrders = pos;
    this.draw();
  }

  constructor() {
    super();
  }

  setMode = (mode: 'qty' | 'cost') => {
    this.setAttribute('mode', mode);
  }

  draw = () => {
    const graph = this;
    const purchaseOrders = this._purchaseOrders;

    if (purchaseOrders.length === 0) {
      graph.innerHTML = `
        <div class="min-h-24 flex items-center justify-center text-text/50 italic">
          No purchase orders placed within this time frame.
        </div>
      `;
      return;
    }

    // calculate totals
    const totalQtyRequested = purchaseOrders.reduce((sum, x) => x.quantity_requested + sum, 0) || 0;
    const totalQtyAccepted = purchaseOrders.reduce((sum, x) => x.quantity_accepted + sum, 0) || 0;
    const totalQtyReceived = purchaseOrders.reduce((sum, x) => x.quantity_received + sum, 0) || 0;
    const totalQtyCancelled = purchaseOrders.reduce((sum, x) => x.quantity_cancelled + sum, 0) || 0;

    const totalCostRequested = purchaseOrders.reduce((sum, x) => (x.quantity_requested * (x.cost_price_cents || 0)) + sum, 0) / 100;
    const totalCostAccepted = purchaseOrders.reduce((sum, x) => (x.quantity_accepted * (x.cost_price_cents || 0)) + sum, 0) / 100;
    const totalCostReceived = purchaseOrders.reduce((sum, x) => (x.quantity_received * (x.cost_price_cents || 0)) + sum, 0) / 100;
    const totalCostCancelled = purchaseOrders.reduce((sum, x) => (x.quantity_cancelled * (x.cost_price_cents || 0)) + sum, 0) / 100;
    const currency = document.getElementById('organization-data')?.dataset?.currencySymbol || '';

    // switch between modes
    const mode: 'qty' | 'cost' = this.getAttribute('mode').toLocaleLowerCase() === 'cost' ? 'cost' : 'qty';
    const totalRequested = mode === 'qty' ? totalQtyRequested : totalCostRequested;
    const totalAccepted = mode === 'qty' ? totalQtyAccepted : totalCostAccepted;
    const totalReceived = mode === 'qty' ? totalQtyReceived : totalCostReceived;
    const totalCancelled = mode === 'qty' ? totalQtyCancelled : totalCostCancelled;
    const maxValue = Math.max(totalRequested, totalAccepted, totalReceived, totalCancelled);

    const data = [
      { x: 0, value: totalRequested },
      { x: 1, value: totalAccepted },
      { x: 2, value: totalReceived },
      { x: 3, value: totalReceived }
    ];

    const cancelledData = [
      { x: 3, value: totalCancelled },
      { x: 4, value: totalCancelled },
      // we add an out-of-view overflow entry here so this separate chart will be aligned with the rest of the centered charts
      { x: 5, value: maxValue },
    ];

    const texts: Record<string, any>[] = [
      { x: 0, value: totalRequested, name: 'Requested', color: 'rgb(var(--theme-border))' },
      { x: 1, value: totalAccepted, name: 'Accepted', color: 'rgb(var(--theme-accent-2))' },
      { x: 2, value: totalReceived, name: 'Received', color: 'rgb(var(--theme-accent))' },
      { x: 3, value: totalCancelled, name: 'Cancelled', color: 'rgb(var(--theme-status-critical))' }
    ];
    for (const row of texts) {
      row.y1 = maxValue * 1.9;
      row.y2 = maxValue * 1.67;
      row.y3 = maxValue * 1.45;
      row.valueText = mode === 'cost' ? `${currency}${row.value.toLocaleString()}` : row.value;

      // calculate status-specific values
      switch (row.name) {
        case 'Accepted':
          if (totalRequested > 0) {
            const acceptanceRate = row.value / totalRequested;
            row.subtitle = `${(acceptanceRate * 100).toPrecision(3)}% Avg. Acceptance Rate`;
          }
          break;
        case 'Received':
          if (totalAccepted > 0) {
            const fillRate = row.value / totalAccepted;
            row.subtitle = `${(fillRate * 100).toPrecision(3)}% Avg. Fill Rate`;
          }
          break;
        case 'Cancelled':
          if (totalRequested > 0) {
            const cancellationRate = row.value / totalRequested;
            row.subtitle = `${(cancellationRate * 100).toPrecision(3)}% Total Cancelled`;
          }
          break;
      }
    }

    const box = graph.getBoundingClientRect();
    const plot = Plot.plot({
      style: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '0.7rem',
      },
      x: {
        axis: null,
        domain: [0, 4],
      },
      y: {
        axis: null,
        domain: [maxValue * -0.5, maxValue * 2],
      },
      width: box.width,
      height: 320,
      marks: [
        // gray rectangular outline for baseline
        Plot.rect([{ x1: 0, x2: 4, y1: 0, y2: maxValue }], {
          x1: 'x1',
          x2: 'x2',
          y1: 'y1',
          y2: 'y2',
          fill: 'rgb(var(--theme-border)/25%)',
        }),
        // gradient fill
        () => htl.svg`<defs>
          <linearGradient id="gradient">
            <stop offset="0%" stop-color="rgb(var(--theme-border))" />
            <stop offset="28%" stop-color="rgb(var(--theme-border))" />
            <stop offset="38%" stop-color="rgb(var(--theme-accent-2))" />
            <stop offset="62%" stop-color="rgb(var(--theme-accent-2))" />
            <stop offset="72%" stop-color="rgb(var(--theme-accent))" />
            <stop offset="100%" stop-color="rgb(var(--theme-accent))" />
          </linearGradient>
        </defs>`,
        // curved area plot for requested/accepted/received
        Plot.areaY(data, {
          x: 'x',
          y: 'value',
          z: null,
          offset: 'center',
          curve: 'bump-x',
          fill: 'url(#gradient)',
        }),
        // area plot just for cancelled (this is separate so the Received column don't curve into Cancelled)
        Plot.areaY(cancelledData, {
          x: 'x',
          y: 'value',
          z: null,
          offset: 'center',
          curve: 'bump-x',
          fill: 'rgb(var(--theme-status-critical))',
        }),
        // vertical grid lines
        Plot.ruleX([0, 1, 2, 3, 4], {
          stroke: 'rgb(var(--theme-border)/50%)'
        }),
        // text labels
        Plot.text(texts, {
          x: 'x',
          y: 'y1',
          dx: 16,
          text: 'name',
          textAnchor: 'start',
          fill: 'color',
          fontSize: '1rem',
          fontWeight: '500',
        }),
        Plot.text(texts, {
          x: 'x',
          y: 'y2',
          dx: 16,
          text: 'valueText',
          textAnchor: 'start',
          fill: 'black',
          fontSize: '2rem',
          fontWeight: '400',
        }),
        Plot.text(texts, {
          x: 'x',
          y: 'y3',
          dx: 16,
          text: 'subtitle',
          textAnchor: 'start',
          fill: 'rgb(var(--theme-text)/75%)',
          fontSize: '0.9rem',
          fontWeight: '600',
        }),
      ]
    });

    // draw the plot
    while (graph.firstChild) graph.removeChild(graph.firstChild);
    graph.appendChild(plot);

    graph.appendChild(htl.html`
      <div class="ml-4 -mt-12 mb-4 button-group">
        <button type="button" class="button small secondary${mode !== 'qty' ? ' bordered' : ''}" onclick=${() => this.setMode('qty')}>
          Quantity
        </button>
        <button type="button" class="button small secondary${mode !== 'cost' ? ' bordered' : ''}" onclick=${() => this.setMode('cost')}>
          Cost
        </button>
      </div>
    `);
  }

  connectedCallback() {
    this.draw();
    window.addEventListener('resize', this.draw);
    window.addEventListener('ui:tab:activate', this.draw);
  }

  disconnectedCallback() {
    //
  }

  adoptedCallback() {
    this.draw();
  }

  attributeChangedCallback() {
    this.draw();
  }
}
